import React from "react";

const PageTitle = ({
    title,
    subtitle,
    titleClass = "mb-0 mt-1",
    titleStyle = { lineHeight: "2rem" },
    subtitleClass = "text-muted my-0 h4",
    ...props
}) => {
    if (!title) return null;
    return (
        <hgroup {...props}>
            <h1 className={titleClass} style={titleStyle}>
                {title}
            </h1>
            {subtitle && <p className={subtitleClass}>{subtitle}</p>}
        </hgroup>
    );
};

export default PageTitle;
