import React from "react";
import AssemblyProvider from "../../AssemblyProvider";
import IndeterminateButton from "../../IndeterminateButton";
import ValidatingField from "../../ValidatingField";
import { withRouter } from "react-router";
import { Label, FormGroup, Input, Form, Alert, Row, Col } from "reactstrap";

const SavedSearchForm = ({
    saveSearchData,
    alertData,
    handleSave,
    searchForm,
    isLoading,
    resetSaveSearch,
    alertOptions,
    successTimeoutRef,
}) => {
    const {
        register,
        handleSubmit,
        formState: { isValid },
    } = searchForm;
    const { ref, ...restOfForm } = register("alertType");

    return (
        <div>
            <Alert color={alertData?.color} isOpen={alertData !== undefined}>
                {alertData?.alertText}
            </Alert>
            {saveSearchData && alertData?.color !== "success" && (
                <AssemblyProvider group="USER_SEARCH" name="USER_SEARCH">
                    {(assembly) => (
                        <Form onSubmit={handleSubmit(handleSave)}>
                            <div className="border-bottom pb-2 mb-2 text-center h4">
                                Search Saved!
                            </div>
                            <Label>
                                Use the following form to tailor your saved
                                search.
                            </Label>
                            <FormGroup>
                                <Label>Search name</Label>
                                <ValidatingField
                                    hideLabel
                                    name="name"
                                    placeholder={saveSearchData?.value}
                                    defaultValueOverride={saveSearchData?.value}
                                    assembly={assembly}
                                    {...searchForm}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Email Alert Frequency</Label>
                                <Input
                                    className="px-2"
                                    type="select"
                                    name="alertType"
                                    innerRef={ref}
                                    defaultValue={
                                        saveSearchData?.more?.emailAlert
                                    }
                                    {...restOfForm}
                                >
                                    {Object.keys(alertOptions)?.map(
                                        (key, i) => (
                                            <option
                                                key={`option-${i}`}
                                                value={alertOptions[key]?.value}
                                            >
                                                {alertOptions[key]?.label}
                                            </option>
                                        )
                                    )}
                                </Input>
                            </FormGroup>
                            <Row className="align-items-center mt-3">
                                <Col className="d-flex">
                                    <FormGroup className="ms-auto">
                                        <IndeterminateButton
                                            type="submit"
                                            className="btn btn-primary"
                                            disabled={!isValid || isLoading}
                                            isLoading={isLoading}
                                        >
                                            Update Search
                                        </IndeterminateButton>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </AssemblyProvider>
            )}
        </div>
    );
};

export default withRouter(SavedSearchForm);
