import React from "react";

/**
 * Image component for the gallery
 * @param {string} height - height of the image
 * @param {function} onClick - function to call on click
 * @param {string} className - class
 * @param {object} props - additional props
 * @returns
 */
const GalleryImage = ({ height, onClick, className, ...props }) => (
    <img
        {...props}
        className={`w-100 ${className}`}
        onError={(e) => (e.target.src = "/assets/no-image-found-360x260.png")}
        loading={props.loading || "lazy"}
        style={{
            objectFit: "cover",
            height: height || "200px",
            cursor: "pointer",
        }}
        onClick={onClick}
    />
);

export default GalleryImage;
