/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *      ContextToggle component
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-16   BL          22320      1            Re-structured files and renamed to index.js
 *
 */
import React from "react";
import styled from "styled-components";

const ContextToggle = ({ children, className, style, dataTest }) => {
    if (children.length !== 2) {
        console.log("Must have 2 ContextToggleChild as children.");
        return null;
    }

    return (
        <div
            className={`d-flex ${className}`}
            style={style}
            data-test={dataTest || "context-toggle"}
        >
            <ContextChild
                className={`border border-primary py-2 px-3 rounded-start ${
                    children[0].props.isSelected
                        ? "bg-primary text-white selected"
                        : "bg-white text-primary"
                } ${children[0].props.className}`}
                style={{
                    cursor: children[1].props.disabled ? "default" : "pointer",
                }}
                data-test={children[0].props.dataTest || "context-child-1"}
                onClick={children[0].props.toggle}
            >
                {children[0]}
            </ContextChild>
            <ContextChild
                className={`border border-left-0 py-2 px-3 border-primary rounded-end ${
                    children[1].props.disabled
                        ? "disabled"
                        : !children[1].props.isSelected
                        ? "bg-white text-primary"
                        : "bg-primary text-white selected"
                } ${children[1].props.className}`}
                style={{
                    cursor: children[1].props.disabled ? "default" : "pointer",
                }}
                data-test={children[1].props.dataTest || "context-child-2"}
                onClick={
                    children[1].props.disabled
                        ? () => {}
                        : children[1].props.toggle
                }
            >
                {children[1]}
            </ContextChild>
        </div>
    );
};

const ContextChild = styled.div`
    &:not(.selected) {
        &:hover {
            background-color: var(--bs-light-gray) !important;
        }
    }
    &.disabled {
        background-color: var(--bs-gray-300) !important;
        color: var(--bs-gray-600) !important;
        &:hover {
            background-color: var(--bs-gray-300) !important;
        }
    }
`;

export default ContextToggle;
