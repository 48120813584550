import React from "react";

const FeatureList = ({ data }) => (
    <div className="container mx-0">
        {(data || []).map((item, i) => (
            <div key={`feature-list-${i}`} className="row mb-2">
                <div className="col-md-4 col-sm-12">
                    <a href={item.valueLink} target="_blank">
                        {item.value}
                    </a>
                </div>
                <div className="col">
                    <span>{item?.labelSuffix}</span>
                    {item?.seeLink && (
                        <span>
                            &nbsp;(See{" "}
                            <a href={item?.seeLink} target="_blank">
                                {item?.seeLink}
                            </a>
                            )
                        </span>
                    )}
                </div>
            </div>
        ))}
    </div>
);

export default FeatureList;
