import React, { memo } from "react";
import { Button } from "reactstrap";
import { resetSearch } from "../../../reducers/searchReducer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useNacLocation } from "../../../hooks/useNacLocation";

const OffMarketBanner = memo(({ isOffmarket }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { state: locationState, state: { nicheItemId } = {} } =
        useNacLocation();
    const offmarket = useSelector(
        (state) => state.pages.offmarket[nicheItemId]
    );
    const { urlPath } = offmarket || {};

    if (!isOffmarket || !offmarket) return null;
    return (
        <div
            className="d-flex align-items-center my-2"
            data-test="offMarketBanner"
        >
            <h4 className="text-muted my-0">Off Market Properties</h4>
            <Button
                className="inverse ms-auto"
                color="primary"
                href={`/${urlPath}`}
                data-test="available-properties-button"
                onClick={(e) => {
                    e.preventDefault();
                    resetSearch(dispatch);
                    history.push({
                        pathname: `/${urlPath}`,
                        state: locationState,
                    });
                }}
            >
                View Available Properties
            </Button>
        </div>
    );
});

export default OffMarketBanner;
