/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *      InfoSlider component. Slider for mobile marker info.
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-22   ASC         2022326    1            Fix image overlap.
 *
 * ------------------------------------------------------------------------------------
 */

import React from "react";
import { Button } from "reactstrap";
import { formatCurrency } from "../../../support/helpers";
import { setSelectedMarker } from "../../../reducers/mapReducer";
import { useQueryParams } from "use-query-params";
import {
    locationQueryModel,
    searchQueryModel,
} from "../../Search/SearchProvider";
import { stringify } from "query-string";

/**
 * Information slider used to display map marker info on mobile.
 */
const InfoSlider = ({ marker, dispatch, history }) => {
    const [locationQuery, setLocationQuery] =
        useQueryParams(locationQueryModel);
    const [searchQuery] = useQueryParams(searchQueryModel);

    const handleClick = () => {
        if (marker.click?.selectRegion) {
            if (marker.click.selectRegion.valueLink) {
                const { valueLink, value } = marker.click.selectRegion;
                const parts = valueLink.split("/");
                parts.shift();
                setLocationQuery({
                    search: parts[1],
                    zip: parts[1],
                    range: parts[2],
                    region: value,
                    mapView: undefined,
                });
            }
            dispatch(setSelectedMarker(undefined));
        } else {
            const searchParams =
                marker?.type === "listing" ? "" : stringify(searchQuery);
            history.push(
                `${marker.click.path}?${searchParams}`,
                marker.click.state
            );
        }
    };
    if (!marker) return null;
    return (
        <div
            className={`bg-white p-1 d-flex w-100 m-0 align-items-center border-top ${marker?.type}`}
            style={{ minHeight: "60px", maxHeight: "70px" }}
            onClick={() => handleClick()}
        >
            {marker?.type === "listing" ? (
                <>
                    <img
                        className="w-auto h-100 me-3"
                        src={
                            marker?.image ||
                            "/assets/no-image-found-360x260.png"
                        }
                        height="100%"
                        style={{ maxHeight: "60px" }}
                        alt="Listing"
                        onError={(e) =>
                            (e.target.src = "/no-image-found-360x260.png")
                        }
                    />
                    <div className="">
                        {marker?.price && (
                            <strong>{formatCurrency(marker.price)}</strong>
                        )}
                        <div>
                            {
                                marker?.bedrooms ? (
                                    <>
                                        {marker?.bedrooms && (
                                            <span className="me-2">
                                                {marker.bedrooms} Bd
                                            </span>
                                        )}
                                        {marker?.bathrooms && (
                                            <span className="me-2">
                                                {marker.bathrooms} Ba
                                            </span>
                                        )}
                                    </>
                                ) : (
                                    <span className="me-2">
                                        {marker?.acreage?.readable
                                            ? marker.acreage.readable
                                            : "Lot"}
                                    </span>
                                ) //TODO: Get acreage sent over in GIS response
                            }
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <h5 className="d-block mb-0">
                            {marker.displayName || marker.value}
                        </h5>
                        {marker?.type === "niche" && (
                            <>
                                {marker.distance && (
                                    <div>{marker.distance} miles</div>
                                )}
                                <div>
                                    {marker?.listings && marker?.listings > 0
                                        ? `Listings: ${marker.listings}`
                                        : "No Listings"}
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
            <div className="ms-auto d-flex flex-column justify-content-center">
                <Button className="inverse" color="primary">
                    View
                </Button>
            </div>
        </div>
    );
};

export default InfoSlider;
