import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import ContextToggle from "../../ContextToggle";
import ContextToggleChild from "../../ContextToggleChild";
import { useSearchData } from "../../../hooks/useSearchData";
import { useQueryParam, StringParam } from "use-query-params";
import { makePlural } from "../../../support/helpers";

const ListingNicheToggle = ({ className = "" }) => {
    const [mapView, setMapView] = useQueryParam("mapView", StringParam);
    const nichetypeSingular = useSelector(
        (state) => state.root.styling.nichetypeSingular
    );
    const isFetching = useSelector((state) => state.search.isFetching);
    const isListingsView = mapView === "listings";
    const { locationData: location, resultsData: results } = useSearchData();

    useEffect(() => {
        if (!isFetching && (results || location)) {
            if (
                (results && results?.total_count === 0) ||
                (!results &&
                    location &&
                    location?.listingsPreview?.length === 0)
            ) {
                setMapView();
            }
        }
    }, [isFetching]);

    return (
        <>
            {location && (
                <ContextToggle
                    className={`w-100 ${className}`}
                    dataTest="listings-niche-context-toggle"
                >
                    <ContextToggleChild
                        toggle={() => setMapView()}
                        isSelected={!isListingsView}
                        className="flex-grow-1 text-center"
                    >
                        <strong>{location?.matchesNicheItems?.matches}</strong>{" "}
                        {nichetypeSingular}{" "}
                        {makePlural(
                            "Result",
                            "Results",
                            location?.matchesNicheItems?.matches
                        )}
                    </ContextToggleChild>
                    <ContextToggleChild
                        toggle={() => setMapView("listings")}
                        isSelected={isListingsView}
                        disabled={results?.total_count === 0}
                        className="flex-grow-1 text-center"
                    >
                        {isFetching === "listings" ? (
                            <Spinner size="sm">{""}</Spinner>
                        ) : (
                            <strong>{results?.total_count || 0}</strong>
                        )}{" "}
                        Listing{" "}
                        {makePlural("Result", "Results", results?.total_count)}
                    </ContextToggleChild>
                </ContextToggle>
            )}
        </>
    );
};

export default ListingNicheToggle;
