import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import {
    getBaseUrl,
    buildQueryParams,
    fetchOverlord,
} from "../support/helpers";

export const getAgent = async (
    sessionId,
    agentId,
    stateId,
    nicheItemId,
    queryItems = {}
) => {
    const query = buildQueryParams({
        stateId: stateId,
        nicheId: nicheItemId,
        ...queryItems,
    });
    const response = await fetchOverlord(
        `${getBaseUrl()}/agents/${agentId}${query}`,
        {
            headers: {
                "Content-Type": "application/json",
                sessionId,
            },
        }
    );
    return response;
};

export const useGetAgent = (initialResults = null) => {
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(initialResults);

    const execute = async (
        agentId,
        stateId = undefined,
        nicheItemId = undefined,
        queryItems = undefined
    ) => {
        try {
            setIsLoading(true);
            const results = await getAgent(
                sessionID,
                agentId,
                stateId,
                nicheItemId,
                queryItems
            );
            setData(results);
            if (error) {
                setError(null); // resetting error
            }
            setIsLoading(false);
            return results;
        } catch (e) {
            setError(e);
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [error, sessionID]), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};
