/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *      Renders the Rich Text Editor using draft-js and React Draft WYSIWYG.
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-12-02   RJP         2022328    1            Deleted the indent and outdent
 * 															buttons from the React Draft
 * 															WYSIWYG toolbar.
 *
 * ------------------------------------------------------------------------------------
 * Set up the version information. This code needs to be in EVERY routine so version
 * information is accessible programmatically even if it is not used any other place
 * in the routine.
 *      Version......'2022300'    -- Julian date portion of the version.
 *      SubVersion...'1'        -- Additional version information.
 * ------------------------------------------------------------------------------------
 */

import React, { useState, useEffect } from "react";
import { Prompt } from "react-router";
import {
    EditorState,
    convertToRaw,
    convertFromRaw,
    convertFromHTML,
    ContentState,
} from "draft-js";
import loadable from "@loadable/component";
import draftToHtml from "draftjs-to-html";
import { Button } from "reactstrap";
import IndeterminateButton from "../../IndeterminateButton";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const DraftWysiwyg = loadable.lib(() => import("react-draft-wysiwyg"));

const RichTextEditor = ({
    defaultContent = " ",
    save,
    cancel,
    defaultEditorState = false,
}) => {
    const [contentChange, setContentChange] = useState(false);
    const [editorState, setEditorState] = useState(defaultEditorState);

    useEffect(() => {
        let contentState;
        if (defaultContent && !defaultEditorState) {
            if (typeof defaultContent === "string") {
                const blocksFromHTML = convertFromHTML(defaultContent);
                contentState = ContentState.createFromBlockArray(
                    blocksFromHTML?.contentBlocks,
                    blocksFromHTML.entityMap
                );
            } else {
                // NOTE -- Adding entityMap just in case it is not part of defaultContent because the conversion
                // functions will break if it is not part of the block content.
                contentState = convertFromRaw({
                    entityMap: {},
                    ...defaultContent,
                });
            }
            if (contentState) {
                setEditorState(EditorState.createWithContent(contentState));
            }
        }
    }, [defaultContent]);

    if (!editorState) return null;
    return (
        <div>
            <Prompt
                when={contentChange}
                message="You have unsaved changes, are you sure you want to leave?"
            />
            <DraftWysiwyg fallback={<div>Loading....</div>}>
                {({ Editor }) => (
                    <Editor
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor border border-top-0 p-1"
                        toolbarClassName="mb-0"
                        onEditorStateChange={(editorState) =>
                            setEditorState(editorState)
                        }
                        onContentStateChange={() =>
                            !contentChange && setContentChange(true)
                        }
                        stripPastedStyles={true}
                        toolbar={{
                            options: [
                                "inline",
                                "blockType",
                                "list",
                                "link",
                                "history",
                            ],
                            inline: {
                                options: ["bold", "italic", "underline"],
                            },
                            blockType: {
                                options: ["Normal", "H3", "H4", "H5", "H6"],
                            },
                            list: {
                                options: ["unordered", "ordered"],
                            },
                            link: { defaultTargetOption: "_blank" },
                        }}
                    >
                        <textarea
                            disabled
                            value={draftToHtml(
                                convertToRaw(editorState.getCurrentContent())
                            )}
                        />
                    </Editor>
                )}
            </DraftWysiwyg>
            <div className="d-flex my-3">
                <Button
                    color="link"
                    className="ms-auto"
                    onClick={() => cancel()}
                >
                    Cancel
                </Button>
                <IndeterminateButton
                    onClick={async () => {
                        await save(
                            convertToRaw(editorState.getCurrentContent())
                        );
                    }}
                >
                    Save
                </IndeterminateButton>
            </div>
        </div>
    );
};

export default RichTextEditor;
