import React from "react";

/**
 * Wraps a component in an error boundry silance a js error
 * and send a log request to the web app.
 */
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        try {
            let baseUrl = "";
            if (location.origin.includes("localhost"))
                baseUrl = "http://localhost:3006";
            fetch(`${baseUrl}/logJsError`, {
                method: "POST",
                body: JSON.stringify({
                    message: error.toString(),
                    errorInfo: errorInfo,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (e) {
            console.log("unable to reach app error logger");
        }
    }
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            // Right now we are just going to silance the error.
            return <div></div>;
        }

        return this.props.children || null;
    }
}

export default ErrorBoundary;
