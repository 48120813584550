import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useToggle from "../../../hooks/useToggle";
import { Button, ModalFooter, ModalBody } from "reactstrap";
import { useSearchData } from "../../../hooks/useSearchData";
import { getPageType } from "../../../support/helpers";
import { pageTypes } from "../../../reducers/pagesReducer";
import Drawer from "../../Drawer";
import { useNacLocation } from "../../../hooks/useNacLocation";

const DrawerSearchItem = ({ children, reset, label, disabled }) => {
    const {
        locationData: location,
        hasSearched,
        resultsData: results,
    } = useSearchData();
    const [isOpen, toggleIsOpen] = useToggle(false);
    const pageType = pageTypes[getPageType(useParams(), false)];
    const { state: { nicheItemId, developmentId } = {} } = useNacLocation();
    const id = pageType === "developments" ? developmentId : nicheItemId;
    const listingCount = useSelector(
        (state) => state.pages?.[pageType]?.[id]?.listingsMetrics[0]?.value
    );
    const [totalCount, setTotalCount] = useState(listingCount || null);
    const searchedButNoMatches =
        hasSearched && location?.matchesNicheItems?.matches === 0;

    // Setting results in state so that the total count is updated when the results change
    useEffect(() => {
        if (hasSearched) {
            if (results?.total_count || results?.total_count === 0) {
                setTotalCount(results?.total_count);
            }
        } else {
            setTotalCount(listingCount);
        }
    }, [results, listingCount]);

    return (
        <>
            <Button
                disabled={disabled}
                color="primary"
                className={`inverse ms-auto`}
                onClick={toggleIsOpen}
            >
                {label}
            </Button>
            <Drawer isOpen={isOpen} toggle={() => toggleIsOpen()}>
                {isOpen && (
                    <>
                        <ModalBody>{children(isOpen, toggleIsOpen)}</ModalBody>
                        <ModalFooter className="d-flex justify-content-between">
                            <span
                                color="black"
                                className="py-1 px-2"
                                onClick={() => reset({ filtersOnly: true })}
                            >
                                Reset Filters
                            </span>
                            <Button
                                color="primary"
                                className="py-1 px-2"
                                onClick={() => toggleIsOpen(false)}
                            >
                                {totalCount > 0 && !searchedButNoMatches
                                    ? `See ${totalCount} homes`
                                    : `No results`}
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </Drawer>
        </>
    );
};

export default DrawerSearchItem;
