import React from "react";
import { useSelector } from "react-redux";
import { AccordionItem, AccordionBody } from "reactstrap";
import StateRegions from "../../StateRegions";
import RegionTitle from "./RegionTitle";
import StateAccordionHeader from "../StateAccordionHeader";

function AccordionRegions({ id, stateId, isOpen }) {
    const stateData = useSelector((state) => state.pages.states?.[stateId]);
    const { stateRegions } = stateData || {};

    return (
        <AccordionItem>
            <StateAccordionHeader targetId={id} isOpen={isOpen}>
                <RegionTitle />
            </StateAccordionHeader>
            <AccordionBody accordionId={id}>
                <StateRegions regionsList={stateRegions} />
            </AccordionBody>
        </AccordionItem>
    );
}

export default AccordionRegions;
