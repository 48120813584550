import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useGetState } from "../../../hooks/state";
import bulkPageReducer from "../../../reducers/bulkPageReducer";
import { getPageType } from "../../../support/helpers";
import { setIsFetchingPage, setPageError } from "../../../reducers/rootReducer";
import { setPageHeaderInfo } from "../../../reducers/pagesReducer";
import { setMarkers, setGisDefault } from "../../../reducers/mapReducer";
import { stateCenterPoints } from "./stateCenterPoints";
import { useSearchData } from "../../../hooks/useSearchData";
import { useQueryParam, StringParam } from "use-query-params";
import { useNacLocation } from "../../../hooks/useNacLocation";

const State = ({ children }) => {
    const dispatch = useDispatch();
    const [mapView] = useQueryParam("mapView", StringParam);
    const { state: { stateId } = {} } = useNacLocation();
    const stateData = useSelector((state) => state.pages.states?.[stateId]);
    const {
        locationData: location,
        gisData: gis,
        hasSearched,
    } = useSearchData();
    const isRegionsView = mapView !== "lakes" && mapView !== "beaches";
    const isListingsView = mapView === "listings";
    const history = useHistory();
    const routeParams = useParams();
    const isStatePage = getPageType(routeParams, "state");
    const { execute: getState, error } = useGetState();
    const { nicheItemsPreviewInfo, stateRegions, isServiced } = stateData || {};

    useEffect(() => {
        if (stateData?.pageHeaderInfo && getPageType(routeParams, "state")) {
            dispatch(setPageHeaderInfo(stateData?.pageHeaderInfo));
        }
    }, [stateData, dispatch, routeParams]);

    useEffect(() => {
        if (stateId && !stateData && isStatePage) {
            const fetchData = async () => {
                dispatch(setIsFetchingPage(true));
                bulkPageReducer(dispatch, {
                    pageType: "state",
                    ...(await getState(stateId)),
                });
                dispatch(setIsFetchingPage(false));
            };
            if (fetchData) fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateId, isStatePage]);

    useEffect(() => {
        if (isStatePage && stateId) {
            dispatch(setGisDefault()); // "resetting" GIS default. There is no default at the state level RN
            if (isListingsView || (!mapView && hasSearched)) {
                if (gis?.results && Array.isArray(gis?.results)) {
                    dispatch(
                        setMarkers(
                            gis.results.map((listing) => ({
                                ...listing,
                                type: "listing",
                                click: {
                                    path: listing.urlPath,
                                    state: {
                                        listingId:
                                            listing.listingId || listing.id,
                                    },
                                },
                            }))
                        )
                    );
                } else {
                    dispatch(setMarkers([]));
                }
            } else {
                if (location?.matchesNicheItems?.matches === 0) {
                    dispatch(setMarkers([]));
                } else if (
                    !isRegionsView &&
                    location?.matchesNicheItems?.diNicheItems &&
                    nicheItemsPreviewInfo
                ) {
                    const nicheMarkers = [];
                    location?.matchesNicheItems?.diNicheItems.forEach(
                        (item) => {
                            if (!item?.more?.hasChildren) {
                                nicheMarkers.push({
                                    ...nicheItemsPreviewInfo.find(
                                        (ni) => ni.more.id === item.more.id
                                    )?.more,
                                    ...item,
                                    ...item.more,
                                    type: "niche",
                                    click: {
                                        path: item.valueLink,
                                        state: { nicheItemId: item.more.id },
                                    },
                                });
                            }
                        }
                    );
                    dispatch(setMarkers(nicheMarkers));
                } else if (
                    stateRegions &&
                    isRegionsView &&
                    stateRegions.length > 0
                ) {
                    dispatch(
                        setMarkers(
                            stateRegions.map((item) => ({
                                ...item,
                                ...item.more,
                                type: "region",
                                click: {
                                    selectRegion: {
                                        ...item,
                                        state_code: stateId,
                                    },
                                },
                            }))
                        )
                    );
                } else if (isServiced && nicheItemsPreviewInfo) {
                    dispatch(
                        setMarkers(
                            nicheItemsPreviewInfo.map((item) => ({
                                ...item,
                                ...item.more,
                                type: "niche",
                                click: {
                                    path: item.valueLink,
                                    state: { nicheItemId: item.more.id },
                                },
                            }))
                        )
                    );
                } else if (!isServiced) {
                    dispatch(
                        setMarkers([
                            {
                                ...stateCenterPoints[stateId],
                                type: "state",
                            },
                        ])
                    );
                } else {
                    dispatch(setMarkers([]));
                }
            }
        }
    }, [
        isStatePage,
        stateId,
        isListingsView,
        location,
        nicheItemsPreviewInfo,
        gis,
        stateRegions,
        history,
        isRegionsView,
        dispatch,
        isServiced,
    ]);

    useEffect(() => {
        if (error) {
            dispatch(
                setPageError({
                    page: "State",
                    error: "Error loading state page.",
                })
            );
        }
    }, [dispatch, error]);

    return <>{children}</>;
};

export default State;
