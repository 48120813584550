import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useMediaQuery } from "react-responsive";
import { isMobile as touchDevice } from "react-device-detect";
import loadable from "@loadable/component";
import styled from "styled-components";
import { FaArrowLeft } from "react-icons/fa";
import GalleryImage from "./GalleryImage";
import useToggle from "../../../hooks/useToggle";
import useScreenOrientation from "../../../hooks/useScreenOrientation";
import ImageCarousel from "./ImageCarousel";

const ImageGalleryLib = loadable.lib(() => import("react-image-gallery"));

/**
 * Displays the image grid or carousel for the listing
 * @param {array} images - array of image objects
 * @param {string} titleContent - title for modal view
 * @returns React component
 */
const ImageTiles = ({ images, titleContent = "" }) => {
    const [showImageGallery, setShowImageGallery] = useState(false);
    const [showCarousel, setShowCarousel] = useState(false);
    const [galleryStartIndex, setGalleryStartIndex] = useState(0);
    const isMedium = useMediaQuery({ maxWidth: 768 });
    const screenOrientation = useScreenOrientation();
    const isLandscape = screenOrientation.includes("landscape");
    const [imageModal, toggleImageModal] = useToggle(false);

    useEffect(() => {
        setShowCarousel(isMedium || touchDevice);
    }, [isMedium, touchDevice]);

    const buildGaleryImages = (images) =>
        images.map((image) => {
            return {
                original: image.uri,
                thumbnail: image?.uriThumbnail || image?.uriSlide || image?.uri,
                originalClass: "rounded",
                originalAlt: image.description,
                thumbnailAlt: image.description,
                originalTitle: image.description,
                thumbnailTitle: image.description,
            };
        });

    const toggleModalAndSetGallery = (showGallery = false, photoNumber) => {
        if (photoNumber) setGalleryStartIndex(photoNumber);
        setShowImageGallery(showGallery);
        toggleImageModal();
    };

    useEffect(() => {
        // When the map is visible, we want to prevent the body scroll
        // This should only be set for mobile
        if (typeof document !== "undefined" && document !== null) {
            if (imageModal) {
                document.body.classList.add("overflow-hidden");
            } else {
                document.body.classList.remove("overflow-hidden");
            }
        }
        return () => document.body.classList.remove("overflow-hidden");
    }, [imageModal]);

    const backButton = (
        <div
            className="opacity-50"
            onClick={() => setShowImageGallery(false)}
            aria-label="Close Gallery"
        >
            <FaArrowLeft style={{ height: "1.2em" }} />
        </div>
    );

    return (
        <>
            <div className="container-xxl g-0" data-test="image-tiles">
                <div className="row g-0 bg-dark">
                    {showCarousel ? (
                        <div className={`col-12 p-0`}>
                            <ImageCarousel
                                images={images}
                                onClick={() => toggleImageModal()}
                            />
                        </div>
                    ) : (
                        <>
                            <div
                                className={`${
                                    images.length > 1 ? "col-lg-7 col-md-8" : ""
                                } col-12 p-0`}
                            >
                                <GalleryImage
                                    src={images[0]?.uri}
                                    alt={images[0]?.description}
                                    title={images[0]?.description}
                                    onClick={() =>
                                        toggleModalAndSetGallery(true, 0)
                                    }
                                    height="400px"
                                />
                            </div>
                            {images.length > 1 && (
                                <Tiles className={`col p-0 d-flex flex-wrap`}>
                                    {images.slice(1, 4).map((image, i) => (
                                        <div
                                            key={`image-tile${i}`}
                                            className="tile"
                                        >
                                            <GalleryImage
                                                src={image.uri}
                                                alt={image.description}
                                                title={image.description}
                                                onClick={() =>
                                                    toggleModalAndSetGallery(
                                                        true,
                                                        i + 1
                                                    )
                                                }
                                            />
                                        </div>
                                    ))}
                                    <div
                                        className="tile p-0 bg-dark d-flex align-items-center justify-content-center"
                                        style={{ minHeight: "200px" }}
                                    >
                                        <Button
                                            color="link text-white"
                                            onClick={() =>
                                                toggleModalAndSetGallery(
                                                    true,
                                                    0
                                                )
                                            }
                                        >
                                            See all photos
                                        </Button>
                                    </div>
                                </Tiles>
                            )}
                        </>
                    )}
                </div>
            </div>
            <Modal
                fullscreen={true}
                className="rounded"
                style={
                    showCarousel
                        ? {}
                        : {
                              maxWidth: "1320px",
                              left: "50%",
                              transform: "translate(-50%, 0)",
                              padding: "3rem",
                          }
                }
                isOpen={imageModal}
                toggle={toggleImageModal}
                scrollable={false}
                onClosed={() => {
                    setShowImageGallery(false);
                    setGalleryStartIndex(0);
                }}
            >
                {imageModal && (
                    <>
                        <GalleryHeader
                            toggle={() =>
                                touchDevice && showImageGallery
                                    ? () => {
                                          setShowImageGallery(false);
                                          setGalleryStartIndex(0);
                                      }
                                    : toggleModalAndSetGallery()
                            }
                            close={
                                touchDevice && showImageGallery
                                    ? backButton
                                    : null
                            }
                            className={
                                touchDevice && showImageGallery
                                    ? "border-0 position-absolute w-100"
                                    : ""
                            }
                            style={
                                touchDevice && showImageGallery
                                    ? { zIndex: 1000 }
                                    : {}
                            }
                        >
                            {(!touchDevice ||
                                (touchDevice && !showImageGallery)) &&
                                titleContent}
                        </GalleryHeader>
                        <ModalBody className="p-0">
                            {showImageGallery ? (
                                <GalleryContainer
                                    className={
                                        !showCarousel
                                            ? "p-3"
                                            : `w-100 mobile-gallery ${
                                                  isLandscape ? "landscape" : ""
                                              }`
                                    }
                                >
                                    <ImageGalleryLib
                                        fallback={<div>Loading....</div>}
                                    >
                                        {({ default: ImageGallery }) => (
                                            <ImageGallery
                                                items={buildGaleryImages(
                                                    images
                                                )}
                                                lazyLoad={true}
                                                showFullscreenButton={false}
                                                showPlayButton={false}
                                                showNav={!showCarousel}
                                                showThumbnails={!showCarousel}
                                                infinite={!showCarousel}
                                                thumbnailPosition="left"
                                                disableThumbnailScroll={true}
                                                startIndex={
                                                    galleryStartIndex || 0
                                                }
                                            />
                                        )}
                                    </ImageGalleryLib>
                                </GalleryContainer>
                            ) : (
                                <>
                                    {images.map((image, i) => (
                                        <img
                                            key={`listing-modal-images-${i}`}
                                            className="w-100 mb-2"
                                            alt={image.description}
                                            title={image.description}
                                            src={image?.uri}
                                            onError={(e) =>
                                                (e.target.src =
                                                    "/assets/no-image-found-360x260.png")
                                            }
                                            onClick={() => {
                                                setGalleryStartIndex(i);
                                                setShowImageGallery(true);
                                            }}
                                        />
                                    ))}
                                </>
                            )}
                        </ModalBody>
                    </>
                )}
            </Modal>
        </>
    );
};

const Tiles = styled.div`
    .tile {
        width: 50%;
        @media (max-width: 992px) {
            &:nth-child(n + 2) {
                display: none;
            }
            width: 100%;
        }
    }
`;
const GalleryHeader = styled(ModalHeader)`
    h5 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

const GalleryContainer = styled.div`
    &:not(.mobile-gallery) {
        height: 100%;
        .image-gallery,
        .image-gallery-content,
        .image-gallery-thumbnails-wrapper,
        .image-gallery-thumbnails {
            height: 100% !important;
        }
        .image-gallery-content {
            display: flex;
        }
        && .image-gallery-thumbnails {
            overflow: scroll;
            position: static;
        }
        .image-gallery-thumbnails-wrapper {
            width: auto;
        }
        .image-gallery-slide-wrapper {
            padding: 1rem;
            width: 100%;
            .image-gallery-swipe {
                position: relative;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
        .image-gallery-thumbnail-image,
        .image-gallery-image {
            border-radius: 0.25rem;
        }
        .image-gallery-image:before {
            content: attr(alt);
            opacity: 0;
            visibility: hidden;
            transition: opacity 1s ease-in-out;
        }
        .image-gallery-image:before:hover {
            opacity: 1;
            visibility: visible;
        }
    }
    &.mobile-gallery {
        height: 100vh;
        .image-gallery,
        .image-gallery-content {
            height: 100vh;
        }
        &.landscape .image-gallery-content {
            .image-gallery-slide .image-gallery-image {
                height: 100vh;
                max-height: 100vh;
                width: auto;
            }
            .image-gallery-slide-wrapper {
                width: 100%;
            }
        }
        &:not(.landscape) .image-gallery-slide-wrapper {
            top: 50%;
            transform: translate(0, -50%);
            width: 100% !important;
        }
    }
`;

export default ImageTiles;
