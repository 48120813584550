import { useEffect, useState } from "react";

/**
 * React hook that tracks whether the element at the given ref is in the viewport
 * @param {React.RefObject<HTMLElement>} ref - The element to track
 * @returns {boolean} - Whether the element is in the viewport
 */
export const useInViewport = (ref) => {
    const [inViewport, setInViewport] = useState(false);

    useEffect(() => {
        if (typeof window !== "undefined" && window !== null) {
            const observer = new IntersectionObserver((entries) => {
                setInViewport(entries[0].isIntersecting);
            });
            if (ref && ref.current) {
                observer.observe(ref.current);
            }
            return () => {
                if (ref && ref.current) {
                    observer.unobserve(ref.current);
                }
            };
        }
    }, [ref]);

    return inViewport;
};
