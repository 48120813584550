import React from "react";
import { useSelector } from "react-redux";
import Editor from "../../Editor";

/**
 * Wrapper component for the Editor that determines if the site is in edit mode.
 * This prevents any editor logic from running until we are in edit mode.
 */
const EditorWrapper = ({ children, ...props }) => {
    const isEditable = useSelector((state) => state.root.mode === "edit");

    return (
        <>{isEditable ? <Editor {...props}>{children}</Editor> : children}</>
    );
};

export default EditorWrapper;
