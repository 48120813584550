import React, { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import DateCard from "./DateCard";
import CustomArrow from "./CustomArrow";

const ScheduleShowing = ({
    name,
    control,
    numOfDays = 7,
    startDate = moment().add(1, "days"),
    setValue,
}) => {
    const [selectedDate, setSelectedDate] = useState(startDate);
    const isMobile = useSelector((state) => state.root.isMobile);
    const dates = [...Array(numOfDays)].map((_, i) =>
        moment(startDate).add(i, "days")
    );
    const [leftArrowDisabled, setLeftArrowDisabled] = useState(true);
    const [rightArrowDisabled, setRightArrowDisabled] = useState(false);
    const numOfMobileItems = 2;
    const numOfDesktopItems = 4;

    useEffect(() => {
        setValue(name, selectedDate.format("YYYY-MM-DD"));
    }, [selectedDate]);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <>
                    <div className="fw-bold mb-2">Select a date</div>
                    <CarouselWrapper isMobile={isMobile}>
                        <Carousel
                            arrows={!isMobile}
                            renderArrowsWhenDisabled={true}
                            customLeftArrow={
                                <CustomArrow
                                    arrowDirection="left"
                                    isDisabled={leftArrowDisabled}
                                />
                            }
                            customRightArrow={
                                <CustomArrow
                                    arrowDirection="right"
                                    isDisabled={rightArrowDisabled}
                                />
                            }
                            draggable
                            swipeable
                            keyBoardControl
                            minimumTouchDrag={80}
                            partialVisible={true}
                            focusOnSelect={false}
                            responsive={{
                                desktop: {
                                    breakpoint: { max: 5000, min: 768 },
                                    items: numOfDesktopItems,
                                    partialVisibilityGutter: 10,
                                },
                                mobile: {
                                    breakpoint: { max: 768, min: 0 },
                                    items: numOfMobileItems,
                                    partialVisibilityGutter: 30,
                                },
                            }}
                            className="custom-carousel"
                            beforeChange={(currentItem) => {
                                setLeftArrowDisabled(
                                    currentItem === 0 ? true : false
                                );
                                setRightArrowDisabled(
                                    currentItem >=
                                        dates.length -
                                            (isMobile
                                                ? numOfMobileItems
                                                : numOfDesktopItems)
                                        ? true
                                        : false
                                );
                            }}
                        >
                            {dates.map((date, idx) => (
                                <DateCard
                                    key={idx}
                                    isActive={
                                        date.isSame(selectedDate, "day")
                                            ? true
                                            : false
                                    }
                                    onClick={() => {
                                        setSelectedDate(date);
                                        field.onChange(
                                            date.format("YYYY-MM-DD")
                                        );
                                    }}
                                    date={date}
                                />
                            ))}
                        </Carousel>
                    </CarouselWrapper>
                </>
            )}
        />
    );
};

const CarouselWrapper = styled.div`
    .custom-carousel {
        ${({ isMobile }) =>
            isMobile
                ? "margin: 0 auto; max-width: 100%;"
                : "margin: 0 auto; max-width: 90%;"}
    }
`;

export default ScheduleShowing;
