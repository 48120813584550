import React from "react";
import styled from "styled-components";

const DateCard = ({
    date,
    isActive,
    className = "py-2 rounded text-center mx-1",
    ...props
}) => {
    return (
        <StyledDateCard
            className={`${isActive && "active"} ${className}`}
            {...props}
        >
            <div>{date.format("dddd")}</div>
            <div className="fw-bold">{date.format("MMM D")}</div>
        </StyledDateCard>
    );
};

const StyledDateCard = styled.div`
    border: 1px solid var(--bs-light-gray);
    cursor: pointer;
    &:hover {
        background-color: var(--bs-gray-200);
    }
    &.active {
        border-color: var(--bs-tertiary);
        background-color: color-mix(
            in srgb,
            var(--bs-primary-light) 20%,
            transparent
        );
        color: var(--bs-tertiary);
    }
`;

export default DateCard;
