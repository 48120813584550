import { NicheMain, NicheBottom } from "../Niche";
import { AgentMain, AgentBottom } from "../Agent";
import { StateMain, StateBottom } from "../State";
import {
    ListingBottom,
    ListingHeader,
    ListingTop,
    ListingMain,
} from "../Listing";

const TemplateItemProvider = ({ children, page, isOffmarket }) => {
    const templateItems = {
        listing: {
            HeaderContent: ListingHeader,
            TopContent: ListingTop,
            BottomContent: ListingBottom,
            MainContent: ListingMain,
            hideHeaderBar: isOffmarket,
        },
        agent: {
            hideHeaderBar: true,
            MainContent: AgentMain,
            BottomContent: AgentBottom,
        },
        development: {
            MainContent: NicheMain,
            BottomContent: NicheBottom,
        },
        offmarket: {
            MainContent: NicheMain,
            BottomContent: NicheBottom,
        },
        niche: {
            MainContent: NicheMain,
            BottomContent: NicheBottom,
        },
        state: {
            MainContent: StateMain,
            BottomContent: StateBottom,
        },
    };

    return children(templateItems[page] || {});
};

export default TemplateItemProvider;
