import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import DiItems from "../../DisplayItems/DiItems";
import { getPageType } from "../../../support/helpers";
import { pageTypes } from "../../../reducers/pagesReducer";
import EditorWrapper from "../../Editor/EditorWrapper";
import bulkPageReducer from "../../../reducers/bulkPageReducer";
import { useGetNiche } from "../../../hooks/niche";
import { useNacLocation } from "../../../hooks/useNacLocation";

const ShortBio = () => {
    const dispatch = useDispatch();
    const { state: { stateId, nicheItemId, developmentId, agentId } = {} } =
        useNacLocation();
    const { execute: getNiche } = useGetNiche();
    const pageType = pageTypes[getPageType(useParams(), false)];
    const id = pageType === "developments" ? developmentId : nicheItemId;
    const { shortBio, fullBio, displayName } =
        useSelector((state) => state.pages?.[pageType]?.[id]) || {};
    const isOffMarket = useSelector((state) => state.search.isOffMarket);

    const getOffMarket = async () => {
        bulkPageReducer(dispatch, {
            pageType: "nicheItem",
            ...(await getNiche(
                stateId,
                nicheItemId,
                { gispreview: true },
                true,
                agentId
            )),
            mode: "edit",
        });
    };

    return (
        <div className="mt-3">
            {isOffMarket ? (
                <DiItems
                    items={[shortBio]}
                    wrapperClsses="d-inline-block"
                    labelClasses="d-none"
                    valueIsHtml={true}
                    dataTest="nicheItem-short-bio"
                />
            ) : (
                <EditorWrapper
                    category="NICHE-ITEMS"
                    item="Short-Bio"
                    id={id}
                    editorName="RichTextEditor"
                    defaultContent={shortBio?.value}
                    publishCallback={async () => {
                        bulkPageReducer(dispatch, {
                            pageType: "nicheItem",
                            ...(await getNiche(
                                stateId,
                                nicheItemId,
                                { gispreview: true },
                                false,
                                agentId
                            )),
                            mode: "edit",
                        });
                        getOffMarket();
                    }}
                >
                    <DiItems
                        items={[shortBio]}
                        wrapperClsses="d-inline-block"
                        labelClasses="d-none"
                        valueIsHtml={true}
                        dataTest="nicheItem-short-bio"
                    />
                </EditorWrapper>
            )}

            {shortBio && fullBio && (
                <div className="text-end">
                    <Link
                        to="#nicheBottom"
                        onClick={(e) => {
                            e.preventDefault();
                            const element =
                                document.getElementById("nicheBottom");
                            element.scrollIntoView({ behavior: "smooth" });
                        }}
                    >
                        More About {displayName}
                    </Link>
                </div>
            )}
        </div>
    );
};

export default ShortBio;
