import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { getBaseUrl, fetchOverlord } from "../support/helpers";

export const contactRequest = async (sessionId, options) => {
    const response = await fetchOverlord(`${getBaseUrl()}/contact`, {
        ...options,
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            sessionId,
            ...options?.headers,
        },
    });
    return response;
};

export const useContact = (initialResults = null) => {
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(initialResults);

    const execute = async (options) => {
        try {
            setIsLoading(true);
            setError(null);
            const results = await contactRequest(sessionID, options);
            setData(results);
            setIsLoading(false);
            return results;
        } catch (e) {
            setData(null);
            setError(e);
            setIsLoading(false);
            return undefined;
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [sessionID]), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};
