export const stateCenterPoints = {
    AL: { latitude: 32.31823, longitude: -86.902298 },
    AK: { latitude: 66.160507, longitude: -153.369141 },
    AZ: { latitude: 34.048927, longitude: -111.093735 },
    AR: { latitude: 34.799999, longitude: -92.199997 },
    CA: { latitude: 36.778259, longitude: -119.417931 },
    CO: { latitude: 39.113014, longitude: -105.358887 },
    CT: { latitude: 41.599998, longitude: -72.699997 },
    DE: { latitude: 39.0, longitude: -75.5 },
    FL: { latitude: 27.994402, longitude: -81.760254 },
    GA: { latitude: 33.247875, longitude: -83.441162 },
    HI: { latitude: 19.741755, longitude: -155.844437 },
    ID: { latitude: 44.068203, longitude: -114.742043 },
    IL: { latitude: 40.0, longitude: -89.0 },
    IN: { latitude: 40.273502, longitude: -86.126976 },
    IA: { latitude: 42.032974, longitude: -93.581543 },
    KS: { latitude: 38.5, longitude: -98.0 },
    KY: { latitude: 37.839333, longitude: -84.27002 },
    LA: { latitude: 30.39183, longitude: -92.329102 },
    ME: { latitude: 45.367584, longitude: -68.972168 },
    MD: { latitude: 39.045753, longitude: -76.641273 },
    MA: { latitude: 42.407211, longitude: -71.382439 },
    MI: { latitude: 44.182205, longitude: -84.506836 },
    MN: { latitude: 46.39241, longitude: -94.63623 },
    MS: { latitude: 33.0, longitude: -90.0 },
    MO: { latitude: 38.573936, longitude: -92.60376 },
    MT: { latitude: 46.96526, longitude: -109.533691 },
    NE: { latitude: 41.5, longitude: -100.0 },
    NV: { latitude: 39.876019, longitude: -117.224121 },
    NH: { latitude: 44.0, longitude: -71.5 },
    NJ: { latitude: 39.833851, longitude: -74.871826 },
    NM: { latitude: 34.307144, longitude: -106.018066 },
    NY: { latitude: 43.0, longitude: -75.0 },
    NC: { latitude: 35.782169, longitude: -80.793457 },
    ND: { latitude: 47.650589, longitude: -100.437012 },
    OH: { latitude: 40.367474, longitude: -82.996216 },
    OK: { latitude: 36.084621, longitude: -96.921387 },
    OR: { latitude: 44.0, longitude: -120.5 },
    PA: { latitude: 41.203323, longitude: -77.194527 },
    RI: { latitude: 41.700001, longitude: -71.5 },
    SC: { latitude: 33.836082, longitude: -81.163727 },
    SD: { latitude: 44.5, longitude: -100.0 },
    TN: { latitude: 35.860119, longitude: -86.660156 },
    TX: { latitude: 31.0, longitude: -100.0 },
    UT: { latitude: 39.41922, longitude: -111.950684 },
    VT: { latitude: 44.0, longitude: -72.699997 },
    VA: { latitude: 37.926868, longitude: -78.024902 },
    WA: { latitude: 47.751076, longitude: -120.740135 },
    WV: { latitude: 39.0, longitude: -80.5 },
    WI: { latitude: 44.5, longitude: -89.5 },
    WY: { latitude: 43.07597, longitude: -107.290283 },
};
