import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Fetching from "../../Fetching";
import DiItems from "../../DisplayItems/DiItems";
import { arrayResolver } from "../../../support/helpers";
import EditorWrapper from "../../Editor/EditorWrapper";
import bulkPageReducer from "../../../reducers/bulkPageReducer";
import { useGetState } from "../../../hooks/state";
import { useNacLocation } from "../../../hooks/useNacLocation";

const Bottom = memo(() => {
    const dispatch = useDispatch();
    const states = useSelector((state) => state.pages.states);
    const { state: { stateId } = {} } = useNacLocation();
    const state = states?.[stateId];
    const description = arrayResolver(state?.description[0]);
    const { execute: getState } = useGetState();

    return (
        <Fetching waitUntil={state}>
            <div
                className="container mt-3 px-3 mb-4"
                data-testid="state-bottom"
            >
                <div className="row g-0 full-bio">
                    <EditorWrapper
                        category="STATES"
                        item="Description"
                        id={stateId}
                        editorName="RichTextEditor"
                        defaultContent={description?.value}
                        publishCallback={async () => {
                            bulkPageReducer(dispatch, {
                                pageType: "state",
                                ...(await getState(stateId)),
                                mode: "edit",
                            });
                        }}
                    >
                        <DiItems
                            items={arrayResolver(state?.description)}
                            labelClasses="w-100 fs-3 border-bottom mb-2 .h4"
                            LabelEl="h2"
                            valueIsHtml={true}
                        />
                    </EditorWrapper>
                </div>
            </div>
        </Fetching>
    );
});

export default Bottom;
