import React from "react";
import DiItems from "../../DisplayItems/DiItems";

const Highlights = ({ highlights }) => {
    const categories = [
        "bedrooms",
        "totalBaths",
        "fullBaths",
        "sqft",
        "acreage",
        "status",
        "mls#",
        "county",
    ];

    const orderAndFilter = (originalArray, list) => {
        const result = [];
        list.forEach((item) => {
            const foundItem = originalArray.find(
                (arrItem) => arrItem.id === item
            );
            if (foundItem) result.push(foundItem);
        });
        return result;
    };

    return (
        <div className="my-3" data-test="highlights">
            <div className={`d-none d-md-flex flex-wrap`}>
                <DiItems
                    wrapperClsses="flex-fill d-flex flex-column-reverse px-1"
                    items={orderAndFilter(highlights, categories)}
                    labelClasses="d-block text-muted"
                    valueClasses="d-block h4 mb-0"
                />
            </div>
            <div className="d-md-none">
                <h5>More Info</h5>
                <DiItems
                    wrapperClsses="d-flex w-100 text-muted py-2"
                    items={orderAndFilter(highlights, [
                        "category",
                        "status",
                        "sqft",
                        "acreage",
                        "mls#",
                        "county",
                    ])}
                    labelClasses="d-block"
                    valueClasses="d-block mb-0 text-truncate ms-auto"
                />
            </div>
        </div>
    );
};

export default Highlights;
