import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Button } from "reactstrap";
import { FaMap, FaList } from "react-icons/fa";
import HorizontalSearch from "../../Search/HorizontalSearch";
import {
    getElementHeight,
    getPageType,
    elHeightVisible,
    getConfigItem,
} from "../../../support/helpers";
import DefaultMarker from "../../Map/DefaultMarker";
import Curtain from "../../Curtain";
import NicheItemProvider from "../Niche/NicheItemProvider";
import DevelopmentProvider from "../Development/DevelopmentProvider";
import OffmarketProvider from "../Offmarket/OffmarketProvider";
import StateProvider from "../State/StateProvider";
import AgentProvider from "../Agent/AgentProvider";
import ListingProvider from "../Listing/ListingProvider";
import Controlls from "../../Map/controlls/Controlls";
import { resetSearch } from "../../../reducers/searchReducer";
import {
    resetMap,
    setSelectedMarker,
    setActiveMarker,
} from "../../../reducers/mapReducer";
import TemplateItemProvider from "./TemplateItemProvider";
import InfoSlider from "../../Map/InfoSlider";
import useTrackEvent from "../../../hooks/useTrackEvent";
import { useSearchData } from "../../../hooks/useSearchData";
import { useQueryParam, BooleanParam } from "use-query-params";
import ErrorBoundary from "../../ErrorBoundary";
import SelectionContext from "./SelectionContext";
import { useMediaQuery } from "react-responsive";
import { setFeaturedAgent } from "../../../reducers/featuredAgentReducer";
import { useNacLocation } from "../../../hooks/useNacLocation";

// TODO: Move Map column into its own component.

/**
 * Main template rendering component for pages with a map.
 */
const MapSidebar = () => {
    const dispatch = useDispatch();
    const [mapVisibleParam, setMapVisibleParam] = useQueryParam(
        "mapVisible",
        BooleanParam
    );
    const [isMapVisible, setIsMapVisible] = useState(
        mapVisibleParam !== undefined
    );
    const isLarge = useMediaQuery({ minWidth: 992 });
    const { locationData: location } = useSearchData();
    const isTouchDevice = useSelector((state) => state.root.isMobile);

    const markers = useSelector((state) => state.map.markers);
    const selectedMarker = useSelector((state) => state.map.selectedMarker);

    const {
        pathname,
        state: {
            stateId = undefined,
            nicheItemId = undefined,
            developmentId = undefined,
            agentId = undefined,
            listingId,
        } = {},
    } = useNacLocation();
    const isArchived = useSelector(
        (state) => state.pages.listings[listingId]?.identifiers?.isArchived
    );

    const routeParams = useParams();
    const history = useHistory();
    const { niche: nicheParam } = routeParams || {};
    const pageType = getPageType(routeParams, false, agentId);
    const isDevelopment = pageType === "development";
    const isListing = pageType === "listing";
    const isOffmarket = pageType === "offmarket" || isArchived === 1;
    const isState = pageType === "state";
    const [circleData, setCircleData] = useState();
    const [mapheight, setMapHeight] = useState(null);
    const [shouldCluster, setShouldCluster] = useState(false);
    const [mapIsLoaded, setMapIsLoaded] = useState(false);
    const { trackGTM } = useTrackEvent();
    const mapRef = useRef(null);
    const mapKey = getConfigItem("REACT_APP_MAP_API_KEY");

    useEffect(() => {
        // If the page changes, we need to reset the selected marker
        dispatch(setSelectedMarker(undefined));
        dispatch(setActiveMarker({}));
    }, [routeParams]);

    useEffect(() => {
        return () => {
            // When this component unmounts, we want to reset the search
            resetSearch(dispatch);

            // reset map
            resetMap(dispatch);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            isState &&
            location &&
            location?.myFilterDistanceMiles &&
            Math.abs(location?.latitude) > 1 &&
            Math.abs(location?.latitude) > 1
        ) {
            setCircleData({
                center: { lat: location.latitude, lng: location.longitude },
                radius: location.myFilterDistanceMiles / 0.00062137,
            });
        } else if (!isState || !location) {
            setCircleData(undefined);
        }
    }, [location, isState]);

    useEffect(() => {
        if (
            !mapIsLoaded &&
            ((!isTouchDevice && isLarge) ||
                (isTouchDevice && isMapVisible) ||
                (!isLarge && isMapVisible) ||
                isListing)
        ) {
            setMapIsLoaded(true);
        }
    }, [
        mapIsLoaded,
        isTouchDevice,
        isListing,
        isMapVisible,
        isLarge,
        dispatch,
    ]);

    const buildAndSetMapHeight = useCallback(() => {
        if (typeof window !== "undefined" && window !== null) {
            const searchHeight = getElementHeight(["search-bar"]);
            const headerHeight = elHeightVisible(
                document.getElementById("header-container")
            );
            const toggleContainerHeight =
                isMapVisible && location
                    ? getElementHeight(["toggle-container"])
                    : 0;
            const height = searchHeight + headerHeight + toggleContainerHeight;
            setMapHeight(`calc(100vh - ${height}px)`);
        }
    }, [isMapVisible, location]);

    useEffect(() => {
        // When the map is visible, we want to prevent the body scroll
        // This should only be set for mobile
        if (typeof document !== "undefined" && document !== null) {
            if (isMapVisible) {
                document.body.classList.add("overflow-hidden");
            } else {
                document.body.classList.remove("overflow-hidden");
            }
        }
        return () => document.body.classList.remove("overflow-hidden");
    }, [isMapVisible]);

    // When the page changes, we want reset the featured agent in global state
    // TODO: refactor featured agents to not rely on gloabl state for featured data.
    useEffect(() => {
        dispatch(setFeaturedAgent());
    }, [dispatch, setFeaturedAgent, pathname]);

    useEffect(() => {
        if (pathname || (isMapVisible && location)) {
            buildAndSetMapHeight();
        }
    }, [buildAndSetMapHeight, isMapVisible, location, pathname]);

    useEffect(() => {
        if (!mapheight) {
            buildAndSetMapHeight();
        }
        document.addEventListener("scroll", buildAndSetMapHeight);
        document.addEventListener("resize", buildAndSetMapHeight);
        return () => {
            document.removeEventListener("scroll", buildAndSetMapHeight);
            document.removeEventListener("resize", buildAndSetMapHeight);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Listening for screen size change to determine if the map should be visible or not
    useEffect(() => {
        if (!isLarge && mapVisibleParam && !isMapVisible) {
            setIsMapVisible(true);
        } else if (isMapVisible && (!mapVisibleParam || isLarge)) {
            setIsMapVisible(false);
        }
    }, [isLarge, isMapVisible, mapVisibleParam]);

    return (
        <StateProvider>
            <NicheItemProvider isOffmarket={isOffmarket}>
                <DevelopmentProvider>
                    <OffmarketProvider isOffmarket={isOffmarket}>
                        <ListingProvider>
                            <AgentProvider>
                                <TemplateItemProvider
                                    page={pageType}
                                    isOffmarket={isOffmarket}
                                >
                                    {({
                                        HeaderContent,
                                        TopContent,
                                        MainContent,
                                        BottomContent,
                                        hideHeaderBar,
                                    }) => (
                                        <>
                                            <div
                                                className={`container-fluid px-0 ${
                                                    isListing
                                                        ? "container-xxl"
                                                        : ""
                                                }`}
                                                data-testid="map-sidebar"
                                            >
                                                {!hideHeaderBar &&
                                                    !isOffmarket && (
                                                        <div
                                                            className={
                                                                !isMapVisible
                                                                    ? "position-sticky"
                                                                    : "position-static"
                                                            }
                                                            style={{
                                                                zIndex: 11,
                                                                top: 0,
                                                            }}
                                                            id="search-bar"
                                                            data-test="search-bar"
                                                        >
                                                            <div className="p-1 px-md-3 py-md-2 bg-light row g-0 border-bottom">
                                                                <ErrorBoundary>
                                                                    {HeaderContent ? (
                                                                        <HeaderContent />
                                                                    ) : (
                                                                        <HorizontalSearch
                                                                            isOffmarket={
                                                                                isOffmarket
                                                                            }
                                                                        />
                                                                    )}
                                                                </ErrorBoundary>
                                                            </div>
                                                        </div>
                                                    )}
                                                <ErrorBoundary>
                                                    {TopContent &&
                                                        !isMapVisible && (
                                                            <TopContent />
                                                        )}
                                                </ErrorBoundary>
                                                <div className="row g-0 mx-0 w-100 bg-white position-relative">
                                                    <ErrorBoundary>
                                                        <MainWrapper
                                                            className={`px-0 ${
                                                                isMapVisible
                                                                    ? "d-none"
                                                                    : ""
                                                            } ${
                                                                isListing
                                                                    ? "col-lg-5 col-xg-6"
                                                                    : ""
                                                            }`}
                                                            isTouchDevice={
                                                                isTouchDevice
                                                            }
                                                            isListing={
                                                                isListing
                                                            }
                                                        >
                                                            {MainContent && (
                                                                <MainContent
                                                                    key={`state-${
                                                                        isDevelopment
                                                                            ? developmentId
                                                                            : nicheParam
                                                                            ? nicheItemId
                                                                            : stateId
                                                                    }`}
                                                                    isDevelopment={
                                                                        isDevelopment
                                                                    }
                                                                    isOffmarket={
                                                                        isOffmarket
                                                                    }
                                                                    setShouldCluster={
                                                                        setShouldCluster
                                                                    }
                                                                />
                                                            )}
                                                        </MainWrapper>
                                                    </ErrorBoundary>
                                                    <div
                                                        className={`${
                                                            isListing
                                                                ? "col-12 col-lg-7 col-xg-6"
                                                                : `${
                                                                      isMapVisible
                                                                          ? "h-100 g-0"
                                                                          : "col px-0"
                                                                  }`
                                                        }`}
                                                        data-test="map-container"
                                                        style={
                                                            isTouchDevice &&
                                                            !isMapVisible &&
                                                            !isListing
                                                                ? {
                                                                      position:
                                                                          "absolute",
                                                                      left: "-9999px",
                                                                  }
                                                                : {}
                                                        }
                                                    >
                                                        {mapKey && (
                                                            <Curtain
                                                                show={
                                                                    !markers?.[0]
                                                                        ?.latitude &&
                                                                    isListing
                                                                }
                                                                className="curtain h-100"
                                                                label="Location not available"
                                                            >
                                                                <div
                                                                    className={
                                                                        (isLarge ||
                                                                            !isTouchDevice) &&
                                                                        !isListing
                                                                            ? "position-sticky"
                                                                            : "position-static"
                                                                    }
                                                                    style={
                                                                        isListing
                                                                            ? {
                                                                                  height: "400px",
                                                                              }
                                                                            : {
                                                                                  top: getElementHeight(
                                                                                      [
                                                                                          "search-bar",
                                                                                      ]
                                                                                  ),
                                                                                  height: mapheight,
                                                                                  transition:
                                                                                      "height .3s",
                                                                              }
                                                                    }
                                                                >
                                                                    <ErrorBoundary>
                                                                        {(mapIsLoaded ||
                                                                            mapRef.current !==
                                                                                null) && (
                                                                            <div
                                                                                className="position-relative h-100"
                                                                                ref={
                                                                                    mapRef
                                                                                }
                                                                            >
                                                                                <SelectionContext
                                                                                    stateId={
                                                                                        stateId
                                                                                    }
                                                                                    nicheItemId={
                                                                                        nicheItemId
                                                                                    }
                                                                                    agentId={
                                                                                        agentId
                                                                                    }
                                                                                    className="position-absolute"
                                                                                    style={{
                                                                                        top: "10px",
                                                                                        left: "10px",
                                                                                        zIndex: 10,
                                                                                    }}
                                                                                />
                                                                                {!isTouchDevice && (
                                                                                    <Controlls
                                                                                        className="position-absolute d-none d-lg-flex"
                                                                                        style={{
                                                                                            zIndex: 10,
                                                                                            bottom: "25px",
                                                                                            right: "10px",
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                                <DefaultMarker
                                                                                    zoom={
                                                                                        7
                                                                                    }
                                                                                    size="550x550"
                                                                                    circleData={
                                                                                        circleData
                                                                                    }
                                                                                    useCluster={
                                                                                        shouldCluster
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </ErrorBoundary>
                                                                </div>
                                                            </Curtain>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <ErrorBoundary>
                                                {BottomContent &&
                                                    !isMapVisible && (
                                                        <BottomContent
                                                            isDevelopment={
                                                                isDevelopment
                                                            }
                                                            isOffmarket={
                                                                isOffmarket
                                                            }
                                                        />
                                                    )}
                                            </ErrorBoundary>
                                            {!isListing && (
                                                <>
                                                    <div
                                                        className={`position-fixed d-flex justify-content-center ${
                                                            isTouchDevice
                                                                ? ""
                                                                : "d-lg-none"
                                                        }`}
                                                        style={{
                                                            bottom: "16px",
                                                            left: "50%",
                                                            transform:
                                                                "translate(-50%,0)",
                                                            zIndex: 12,
                                                        }}
                                                    >
                                                        <Button
                                                            color="primary"
                                                            className="inverse px-3 d-flex align-items-center justify-content-center"
                                                            disabled={!markers}
                                                            onClick={() => {
                                                                setMapIsLoaded(
                                                                    true
                                                                );
                                                                setMapVisibleParam(
                                                                    isMapVisible
                                                                        ? undefined
                                                                        : true
                                                                );
                                                                // If we toggle, we need to reset the selectd marker
                                                                dispatch(
                                                                    setSelectedMarker(
                                                                        undefined
                                                                    )
                                                                );
                                                                trackGTM({
                                                                    event: `toggle${
                                                                        isMapVisible
                                                                            ? "List"
                                                                            : "Map"
                                                                    }`,
                                                                    action: "click",
                                                                    category:
                                                                        "mapInteraction",
                                                                    type: isMapVisible
                                                                        ? "List View Button"
                                                                        : "Map View Button",
                                                                });
                                                            }}
                                                        >
                                                            {isMapVisible ? (
                                                                <>
                                                                    <FaList />
                                                                    &nbsp;
                                                                    <div className="ml-2">
                                                                        List
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <FaMap />
                                                                    &nbsp;
                                                                    <div className="ml-2">
                                                                        Map
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Button>
                                                    </div>
                                                    {selectedMarker && (
                                                        <div
                                                            className="position-fixed bottom-0 w-100"
                                                            style={{
                                                                zIndex: 13,
                                                            }}
                                                        >
                                                            <InfoSlider
                                                                marker={
                                                                    selectedMarker
                                                                }
                                                                dispatch={
                                                                    dispatch
                                                                }
                                                                history={
                                                                    history
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </TemplateItemProvider>
                            </AgentProvider>
                        </ListingProvider>
                    </OffmarketProvider>
                </DevelopmentProvider>
            </NicheItemProvider>
        </StateProvider>
    );
};

const MainWrapper = styled.div`
    width: ${(props) =>
        props.isListing
            ? ""
            : props.isTouchDevice
            ? "100% !important"
            : "650px !important"};
    @media (max-width: 991px) {
        width: 100% !important;
    }
`;

export default MapSidebar;
