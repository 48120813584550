import React from "react";
import Metrics from "../../Metrics/Metrics";
import { useSelector } from "react-redux";
import PageTitle from "../PageTitle/PageTitle";
import { useSearchData } from "../../../hooks/useSearchData";
import { useNacLocation } from "../../../hooks/useNacLocation";

const StateMetrics = (props) => {
    const { state: { stateId } = {} } = useNacLocation();
    const stateData = useSelector((state) => state.pages.states?.[stateId]);
    const { name, isServiced, listingsMetrics } = stateData || {};
    const { hasSearched, locationQuery, selectedRegion } = useSearchData();

    let title = name,
        subtitle = undefined,
        dataTest = "title-state";
    if (selectedRegion) {
        subtitle = `${selectedRegion.value} (${locationQuery.zip})`;
        dataTest = "region-name";
        // was the search a zip or city? If its a number, it is a zip.
    } else if (!isNaN(locationQuery?.search) && locationQuery?.zip) {
        subtitle = locationQuery.zip;
    } else if (locationQuery?.search) {
        title = locationQuery.search;
        dataTest = "title-city";
    }

    return (
        <div {...props}>
            <PageTitle title={title} subtitle={subtitle} data-test={dataTest} />
            {isServiced && (
                <div className={hasSearched ? "d-flex" : ""}>
                    {!hasSearched && <Metrics metrics={listingsMetrics} />}
                </div>
            )}
        </div>
    );
};

export default StateMetrics;
