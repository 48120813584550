import React from "react";
import styled from "styled-components";
import Loading from "../Loading";
import AdminEditorButtons from "./AdminEditorButtons";
import { FaExclamationTriangle, FaRegEdit } from "react-icons/fa";
import DisappearingAlert from "../DisappearingAlert";
import ContentStatusBadge from "./ContentStatusBadge";
import { Alert } from "reactstrap";
import { useEditor } from "./hooks/useEditor";
import { DynamicComponent } from "./DynamicComponent";

/**
 * Editor for microcontent. This will allow the wrapped area to be presented as editable content.
 */
const Editor = ({
    children,
    editorName,
    category,
    item,
    id,
    defaultContent,
    publishCallback,
    initialUserActions,
    initialIsEditing = false,
    initialHideEditor = true,
}) => {
    const {
        hideEditor,
        alertText,
        setAlertText,
        showUnsupportedWarning,
        isEditing,
        setIsEditing,
        content,
        setContent,
        has,
        data,
        isMicroLoading,
        userActions,
        renderContent,
        save,
        adminBtnsExecute,
    } = useEditor({
        defaultContent,
        initialIsEditing,
        initialUserActions,
        initialHideEditor,
        category,
        item,
        id,
        publishCallback,
        children,
    });

    return (
        <>
            {hideEditor ? (
                children
            ) : (
                <>
                    <DisappearingAlert
                        text={alertText || ""}
                        color="danger"
                        time={3000}
                        setError={() => setAlertText()}
                        shouldShow={!!alertText}
                    />
                    <Alert
                        color="danger"
                        className="mt-3 d-flex align-items-center"
                        isOpen={showUnsupportedWarning}
                    >
                        <div className="me-3">
                            <FaExclamationTriangle size={30} />
                        </div>
                        <div>
                            The content below contains unsupported HTML (video,
                            img, iframe, canvas). If the editor is used, those
                            HTML elements will be removed. To prevent this,
                            please submit a ticket through Monday using the form
                            for the content to be updated manually.
                        </div>
                    </Alert>
                    {isEditing ? (
                        <DynamicComponent
                            componentName={editorName}
                            defaultContent={content}
                            save={save}
                            cancel={() => setIsEditing(false)}
                        />
                    ) : (
                        <>
                            {(has("CREATE") || has("EDIT")) && (
                                <div className="my-4">
                                    <EditorContainer
                                        className={`w-100 rounded-2 p-1 mt-4 ${
                                            isEditing ? "isEditing" : ""
                                        }`}
                                        unsupportedTags={showUnsupportedWarning}
                                    >
                                        <div className="d-flex justify-content-center">
                                            <div className="edit-icon d-flex align-items-center justify-content-center cursor-pointer bg-white rounded-circle">
                                                <span className="position-relative">
                                                    <FaRegEdit />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="w-100">
                                            <ContentStatusBadge
                                                status={
                                                    data?.microContentStatus
                                                }
                                                className="float-end hide-on-hover"
                                            />
                                            {data && (
                                                <AdminEditorButtons
                                                    className="visible-on-hover"
                                                    defaultContent={
                                                        defaultContent
                                                    }
                                                    execute={adminBtnsExecute}
                                                    setContent={(content) =>
                                                        setContent(content)
                                                    }
                                                    setIsEditing={(bool) =>
                                                        setIsEditing(bool)
                                                    }
                                                    userActions={userActions}
                                                />
                                            )}
                                            <div
                                                onClick={() =>
                                                    setIsEditing(true)
                                                }
                                            >
                                                {isMicroLoading ? (
                                                    <Loading />
                                                ) : (
                                                    renderContent(
                                                        content,
                                                        children
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </EditorContainer>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

const EditorContainer = styled.div`
    height: 100%;
    border: solid 1px var(--bs-light-gray);
    transition: 0.2s;

    .visible-on-hover {
        opacity: 0;
        transition: 0.2s;
        visibility: hidden;
        display: block;
    }

    .edit-icon {
        border: solid 1px var(--bs-light-gray);
        height: 50px;
        width: 50px;

        span {
            font-size: 30px;
            color: var(--bs-light-gray);
        }
        svg {
            position: absolute;
            top: -16px;
            left: -13px;
        }
    }

    > div {
        margin-top: -25px;
    }

    &.isEditing {
        transition: height 2s;
    }

    &:hover {
        cursor: pointer;
        height: 100%;
        border: solid 1px
            var(
                ${(props) =>
                    props.unsupportedTags ? "--bs-danger" : "--bs-primary"}
            );
        background-color: rgba(
            ${(props) =>
                props.unsupportedTags
                    ? "132, 32, 41,0.075"
                    : "0,119,192, 0.075"}
        );
        transition: 0.2s;

        .hide-on-hover {
            display: none;
        }
        .visible-on-hover {
            transition: transform ease-out 0.3s;
            opacity: 1;
            transition: 0.4s;
            visibility: visible;
        }
        .edit-icon {
            border-color: var(
                ${(props) =>
                    props.unsupportedTags ? "--bs-danger" : "--bs-primary"}
            );
            span: {
                color: var(
                    ${(props) =>
                        props.unsupportedTags ? "--bs-danger" : "--bs-primary"}
                );
            }
            svg {
                color: var(
                    ${(props) =>
                        props.unsupportedTags ? "--bs-danger" : "--bs-primary"}
                );
            }
        }
    }
`;

export default Editor;
