/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *      Fetching component
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-16   BL          22320      1            Re-structured files and renamed to index.js
 *
 */
import React from "react";
import { useSelector } from "react-redux";
import Loading from "../Loading";

const Fetching = ({ children, waitUntil }) => {
    const isFetching = useSelector((state) => state.root.isFetching);
    return isFetching || !waitUntil ? <Loading /> : children;
};

export default Fetching;
