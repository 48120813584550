import React from "react";
import DiItems from "../DisplayItems/DiItems";
import styled from "styled-components";

const Default = ({ data }) => {
    return (
        <DefaultWrapper className="list-inline features-default">
            <DiItems
                Wrapper="li"
                wrapperClsses="mb-2"
                items={data}
                labelClasses="w-50 d-inline-block fw-bold align-top"
                valueClasses="w-50 d-inline-block text-muted align-top"
            />
        </DefaultWrapper>
    );
};

const DefaultWrapper = styled.ul`
    column-count: 2;
    @media (max-width: 768px) {
        column-count: 1;
    }
`;

export default Default;
