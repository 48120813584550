import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { getBaseUrl, fetchOverlord } from "../support/helpers";

export const getState = async (sessionId, state, options = {}) => {
    const response = await fetchOverlord(`${getBaseUrl()}/states/${state}`, {
        headers: {
            "Content-Type": "application/json",
            sessionId,
            ...options?.headers,
        },
        ...options,
    });
    return response;
};

export const useGetState = (initialResults = null) => {
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(initialResults);

    const execute = async (state) => {
        try {
            setIsLoading(true);
            setError(null);
            const results = await getState(sessionID, state);
            setData(results);
            setIsLoading(false);
            return results;
        } catch (e) {
            setError(e);
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [sessionID]), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};
