import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import {
    getBaseUrl,
    fetchOverlord,
    buildQueryParams,
} from "../support/helpers";

export const getFeaturedAgent = async (sessionId, rawQuery, options) => {
    const query = buildQueryParams({ ...rawQuery });
    const response = await fetchOverlord(
        `${getBaseUrl()}/agents/featured${query}`,
        {
            ...options,
            headers: {
                "Content-Type": "application/json",
                sessionId,
                ...options?.headers,
            },
        }
    );
    return response;
};

export const useFeaturedAgent = (initialResults = null) => {
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(initialResults);

    const execute = async (query, options, providedSessionId) => {
        try {
            setIsLoading(true);
            setError(null);
            const results = await getFeaturedAgent(
                providedSessionId || sessionID,
                query,
                options
            );
            setData(results);
            if (error) {
                setError(null); // resetting error
            }
            setIsLoading(false);
            return results;
        } catch (e) {
            setError(e);
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [error, sessionID]), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};
