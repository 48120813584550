import React from "react";
import { Button } from "reactstrap";

const SelectorButtons = ({
    className,
    items,
    selected = 0,
    selectedColor = "primary",
    defaultColor = "light",
    buttonClassNames = "",
    buttonStyle = {},
    disabled = false,
    callback,
}) => (
    <div className={`${className} d-flex justify-content-between`}>
        {items.map((item, i) => (
            <Button
                key={`selector-button-${i}`}
                className={`px-2 py-2 ${buttonClassNames}`}
                style={{ ...buttonStyle }}
                disabled={disabled}
                color={selected === item.value ? selectedColor : defaultColor}
                onClick={() => callback(item.value)}
            >
                {item.label}
            </Button>
        ))}
    </div>
);

export default SelectorButtons;
