/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *      CopyLinkButton component
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-16   BL          22320      1            Re-structured files and renamed to index.js
 *
 */
import React from "react";
import { Tooltip } from "reactstrap";
import { copyToClipboard } from "../../support/helpers";
import useToggle from "../../hooks/useToggle";

function CopyLinkButton({
    children,
    url,
    tooltipText = "Copied to clipboard",
    ...props
}) {
    const [tooltipOpen, toggleToolTip] = useToggle(false);

    const handleCopyToClipboard = () => {
        copyToClipboard(url || window.location);
        toggleToolTip(true);
        setTimeout(() => toggleToolTip(false), 1500);
    };

    return (
        <>
            <button
                className="bg-transparent border-0"
                onClick={() => handleCopyToClipboard()}
                id="linkButton"
                {...props}
            >
                {children}
            </button>
            <Tooltip placement="right" isOpen={tooltipOpen} target="linkButton">
                {tooltipText}
            </Tooltip>
        </>
    );
}

export default CopyLinkButton;
