import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Metrics from "../../Metrics/Metrics";
import { getPageType } from "../../../support/helpers";
import { pageTypes } from "../../../reducers/pagesReducer";
import { useSearchData } from "../../../hooks/useSearchData";
import { useNacLocation } from "../../../hooks/useNacLocation";

const NicheItemMetrics = ({ isOffmarket }) => {
    const { resultsData: results, searchQuery } = useSearchData();
    const { state: { nicheItemId, developmentId } = {} } = useNacLocation();
    const pageType = pageTypes[getPageType(useParams(), false)];
    const id = pageType === "developments" ? developmentId : nicheItemId;
    const listingsMetrics = useSelector(
        (state) => state.pages?.[pageType]?.[id]?.listingsMetrics
    );
    const pageListing = useSelector(
        (state) => state.pages?.[pageType]?.[id]?.listingsPreview.total_count
    );

    // if ONLY the items in 'excludeInSearch' are in the searchQuery
    // we want to show the metrics component
    const excludeInSearch = ["sort_order", "page"];
    const hasSearched = !Object.keys(searchQuery)
        .reduce((acc, curr) => {
            if (searchQuery[curr] !== undefined) acc.push(curr);
            return acc;
        }, [])
        .every((item) => excludeInSearch.includes(item));

    if (!listingsMetrics) return null;
    return (
        <div data-test="nicheItem-metrics">
            {hasSearched || results?.total_count === 0 ? (
                <span className="me-3">
                    <span className="h5 me-1" data-test="listing-count">
                        {results?.total_count}
                    </span>
                    <span className="text-uppercase small">Listings</span>
                </span>
            ) : !isOffmarket && pageListing !== 0 ? (
                <Metrics metrics={listingsMetrics} />
            ) : (
                <span className="me-3">
                    <span className="h5 me-1" data-test="listing-count">
                        {pageListing}
                    </span>
                    <span className="text-uppercase small">Listings</span>
                </span>
            )}
        </div>
    );
};

export default NicheItemMetrics;
