import React from "react";
import { useSelector } from "react-redux";
import { useSearchQueryParam } from "../../hooks/useSearchQueryParam";
import ToggleButton from "./ToggleButton";
import useTrackEvent from "../../hooks/useTrackEvent";
import { useNacLocation } from "../../hooks/useNacLocation";

const MegaAssociates = (props) => {
    const { state: { nicheItemId: niche_item_id = undefined } = {} } =
        useNacLocation();
    const styling = useSelector((state) => state.root.styling);
    const isFetching = useSelector((state) => state.search.isFetching);
    const megaAssociates = useSelector(
        (state) => state.pages.nicheItems[niche_item_id].megaAssociates
    );
    const [megaParam, setMega] = useSearchQueryParam("mega");
    const { trackGTM } = useTrackEvent();
    if (!megaAssociates) return null;

    const { dIMegaAssociates, spansStates } = megaAssociates;

    const handlToggle = (value, id) => {
        // remove the mega item from the array
        if (value && megaParam !== undefined) {
            if (megaParam.length > 1) {
                megaParam.splice(megaParam.indexOf(id), 1);
                setMega(megaParam);
            } else {
                setMega(null);
            }
        } else {
            setMega([...(megaParam || []), id]);
        }
        trackGTM({
            event: `userSearch`,
            action: "filter",
            type: "mega associates",
            category: "user_action",
            value: value,
        });
    };

    return (
        <div {...props}>
            <strong>
                This {styling.nichetypeSingular} spans{" "}
                {spansStates || "multiple"} States
            </strong>
            {dIMegaAssociates.map((meggaAssociateDI, i) => {
                const meggaAssociate = meggaAssociateDI[0];
                return (
                    <div
                        key={`megga-associate-${i}`}
                        className={`mb-1 ms-2 d-flex`}
                    >
                        <div>Include Listings From {meggaAssociate.value}</div>
                        <ToggleButton
                            className="toggle-button ms-auto"
                            disabled={isFetching}
                            value={
                                megaParam &&
                                megaParam.includes(meggaAssociate.more.id)
                            }
                            onToggle={(value) =>
                                handlToggle(value, meggaAssociate.more.id)
                            }
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default MegaAssociates;
