import React, { useRef } from "react";
import { useFetchCategory } from "./hooks/useFetchCategory";
import Feature from "../../../features/Feature";
import GroupedFeatures from "../../../features/GroupedFeatures";

const PlacesFeature = ({ title, latitude, longitude }) => {
    const ref = useRef(null);
    const { data } = useFetchCategory(
        ref,
        title.toLowerCase(),
        latitude,
        longitude
    );

    return (
        <span ref={ref}>
            <Feature
                title={title}
                show={Array.isArray(data) && data.length}
                monitor={true}
            >
                <GroupedFeatures data={data} />
            </Feature>
        </span>
    );
};

export default PlacesFeature;
