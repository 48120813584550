import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ContextToggle from "../../ContextToggle";
import ContextToggleChild from "../../ContextToggleChild";
import { useSearchData } from "../../../hooks/useSearchData";
import { useQueryParam, StringParam } from "use-query-params";
import { getPageType } from "../../../support/helpers";

const LakeRegionToggle = ({ className, style, stateId }) => {
    const [mapView, setMapView] = useQueryParam("mapView", StringParam);
    const states = useSelector((state) => state.pages.states);
    const isStatePage = getPageType(useParams(), "state");
    const isRegionsView = mapView !== "lakes";
    const { locationData: location } = useSearchData();
    const nichetypePlural = useSelector(
        (state) => state.root.styling.nichetypePlural
    );

    if (!isStatePage) return null;
    const state = states?.[stateId];
    const { stateRegions } = state || {};

    return (
        <>
            {!location && stateRegions && stateRegions.length > 0 && (
                <ContextToggle className={className} style={style}>
                    <ContextToggleChild
                        toggle={() => setMapView()}
                        isSelected={isRegionsView}
                        className="flex-grow-1 text-center"
                    >
                        View Regions
                    </ContextToggleChild>
                    <ContextToggleChild
                        toggle={() => setMapView("lakes")}
                        isSelected={!isRegionsView}
                        className="flex-grow-1 text-center"
                    >
                        View {nichetypePlural}
                    </ContextToggleChild>
                </ContextToggle>
            )}
        </>
    );
};

export default LakeRegionToggle;
