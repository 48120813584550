import React from "react";
import ListButtons from "./ListButtons";
import { useSearchQueryParam } from "../../hooks/useSearchQueryParam";
import useTrackEvent from "../../hooks/useTrackEvent";

const PropertyType = ({ toggleIsOpen }) => {
    const [property_type, setPropertyType] =
        useSearchQueryParam("property_type");
    const { trackGTM } = useTrackEvent();

    return (
        <ListButtons
            itemName="property_type"
            setValue={(value, options) => {
                if (value !== options[0].value) {
                    setPropertyType(value);
                } else {
                    setPropertyType();
                }
                trackGTM({
                    event: `userSearch`,
                    action: "filter",
                    type: "property_type",
                    category: "user_action",
                    value: value !== options[0].value ? value : "",
                });
            }}
            currentValue={property_type}
            callback={() => toggleIsOpen && toggleIsOpen()}
        />
    );
};

export default PropertyType;
