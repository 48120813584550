import { useQueryParams } from "use-query-params";
import { searchQueryModel } from "../components/Search/SearchProvider";

/**
 * Hook that mimics useQueryParam but sets page to null.
 * @returns [searchQueryValue, setQueryParam]
 */
export const useSearchQueryParam = (name) => {
    const [searchQuery, setSearchQuery] = useQueryParams(searchQueryModel);

    const setQueryParam = (value) => {
        setSearchQuery({
            [name]: value,
            page: null,
        });
    };

    return [searchQuery[name], setQueryParam];
};
