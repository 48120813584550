import React from "react";
import styled from "styled-components";
import NicheItem from "./NIcheItem";

const NicheItems = ({ columnCount, items, filterValue }) => (
    <ItemsWrapper columnCount={columnCount}>
        {items &&
            items.map((item, i) => (
                <NicheItem
                    key={`di-${item.id}-${i}`}
                    dataItem={item}
                    filterValue={filterValue}
                />
            ))}
    </ItemsWrapper>
);

const ItemsWrapper = styled.div`
    column-count: ${(props) => props.columnCount || 2};
    @media (max-width: 576px) {
        column-count: ${(props) => props.columnCount || 1};
    }
    .niche-item {
        padding-left: 5px;
        &:hover {
            padding-left: 0;
            border-left: 5px solid var(--bs-primary);
        }
        a {
            padding-left: 3px !important;
        }
    }
`;

export default NicheItems;
