import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { getBaseUrl, fetchOverlord } from "../support/helpers";

export const emailAFriend = async (sessionId, listingId, options) => {
    const response = await fetchOverlord(
        `${getBaseUrl()}/emailFriend/${listingId}`,
        {
            ...options,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                sessionId,
                ...options?.headers,
            },
        }
    );
    return response;
};

export const useEmailAFriend = (initialResults = null) => {
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(initialResults);

    const execute = async (listingId, options) => {
        try {
            setIsLoading(true);
            setError(null);
            const results = await emailAFriend(sessionID, listingId, options);
            setData(results);
            setIsLoading(false);
            return results;
        } catch (e) {
            setError(e);
            setIsLoading(false);
            throw e;
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [sessionID]), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};
