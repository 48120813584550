import React from "react";
import Metric from "./Metric";

const Metrics = ({ metrics }) => {
    return (
        <>
            {metrics &&
                metrics.map((metric, i) => {
                    const { value, labelSuffix } = metric[0] || metric;
                    return metric ? (
                        <Metric
                            key={`metric-${i}`}
                            label={labelSuffix}
                            value={value}
                        />
                    ) : (
                        <></>
                    );
                })}
        </>
    );
};

export default Metrics;
