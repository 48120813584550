/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *      Parces a single Data Item into JSX (HTML).
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-15   ASC         2022319    1            Remove agentId from location
 *                                                          state builder.
 *
 * ------------------------------------------------------------------------------------
 * Set up the version information. This code needs to be in EVERY routine so version
 * information is accessible programmatically even if it is not used any other place
 * in the routine.
 *      Version......'2022319'    -- Julian date portion of the version.
 *      SubVersion...'1'        -- Additional version information.
 * ------------------------------------------------------------------------------------
 */

import React from "react";
import { Button } from "reactstrap";
import { withRouter } from "react-router";
import { buildPath } from "../../support/helpers";
import draftToHtml from "draftjs-to-html";

const DiItem = ({
    id,
    type,
    history,
    labelSuffix,
    labelSuffixLink,
    value,
    itemPreview,
    valueLink,
    classes,
    linkClasses = "",
    listingsClasses = "",
    isHtml,
    Disp,
    noDisplayWrapper = false,
    buttonColor = "link",
    more,
    clickHandler,
    linkQueryParams = "",
    excludeIdsFromState = [],
}) => {
    const label = type === "suffix" ? labelSuffix : itemPreview || value;
    const link =
        more && more.uRL
            ? more.uRL
            : type === "suffix"
            ? labelSuffixLink
            : valueLink;
    const El = Disp ? "div" : "span";

    const handleInternalLink = async (e, more, rawPath, id) => {
        e.preventDefault();
        if (clickHandler) await clickHandler();
        let path = buildPath(rawPath);

        // Removing param lhr_id as its not needed while in the app.
        if (path.includes("lhr_id")) {
            const pathParts = path.split("?");
            path = pathParts[0];
        }

        const locationState = { ...history.location.state };
        delete locationState.agentId; // remove agentId to start. We dont want to carry this through the app unless its intentional.
        if (more?.stateID || more?.sID || more?.stateCode)
            locationState.stateId =
                more?.stateID || more?.sID || more?.stateCode;
        // breadcrumb_4nicheItem - this is used on the list of Niche items on the Agent page.
        if (
            more?.nicheItemID ||
            id === "itemPreview" ||
            id === "breadcrumb_4nicheItem"
        )
            locationState.nicheItemId = more?.nicheItemID || more?.id;
        if (id === "development" || id === "breadcrumb_4nicheItem_dev") {
            locationState.developmentId = more?.id;
            locationState.nicheItemId = more?.nicheItemId || more?.nicheItemID;
        } else if (
            !excludeIdsFromState.includes("agentId") &&
            (more?.agentID || id === "state_agent")
        ) {
            locationState.agentId = more?.agentID || more?.id;
        }
        history.push(buildPath(path), locationState);
    };

    const buildDataTest = (item) => {
        try {
            return `data-item-${label
                .toString()
                .replace(/ /g, "-")
                .toLowerCase()}`;
        } catch {
            return item;
        }
    };

    const buildLink = (link, label, more, id, linkQueryParams) => {
        let isInternal = !link.includes("http");
        return more?.selected ? (
            <strong>{label}</strong>
        ) : isInternal ? (
            <Button
                color={buttonColor}
                className={`text-start border-0 m-0 p-0 ${linkClasses}`}
                href={`${link}${linkQueryParams}`}
                onClick={(e) =>
                    handleInternalLink(e, more, `${link}${linkQueryParams}`, id)
                }
                data-test={buildDataTest(label)}
            >
                {label}{" "}
                {more &&
                    more.listings !== undefined &&
                    typeof more.listings !== "object" && (
                        <div className={`listings ${listingsClasses}`}>
                            Listings: {more.listings}
                        </div>
                    )}
            </Button>
        ) : (
            <a
                className={linkClasses}
                href={`${link}${linkQueryParams}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {label}
            </a>
        );
    };

    const displayHTML = (html) => (
        <div
            dangerouslySetInnerHTML={{
                __html: typeof html === "object" ? draftToHtml(html) : html,
            }}
        />
    );

    return noDisplayWrapper ? (
        <>
            {link
                ? buildLink(link, label, more, id, linkQueryParams)
                : isHtml
                ? displayHTML(label)
                : label}
        </>
    ) : label ? (
        <El className={classes}>
            {link
                ? buildLink(link, label, more, id, linkQueryParams)
                : isHtml
                ? displayHTML(label)
                : label}
        </El>
    ) : (
        <></>
    );
};

export default withRouter(DiItem);
