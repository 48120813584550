import { useState, useEffect } from "react";

const getOrientation = () =>
    (typeof window !== "undefined" &&
        window !== null &&
        window.screen?.orientation?.type) ||
    "";

const useScreenOrientation = () => {
    const [orientation, setOrientation] = useState(getOrientation());
    const hasWindow = typeof window !== "undefined" && window !== null;

    const updateOrientation = () => {
        setOrientation(getOrientation());
    };

    useEffect(() => {
        if (hasWindow)
            window.addEventListener("orientationchange", updateOrientation);
        return () => {
            if (hasWindow)
                window.removeEventListener(
                    "orientationchange",
                    updateOrientation
                );
        };
    }, [hasWindow]);

    return orientation;
};

export default useScreenOrientation;
