import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner, ModalBody } from "reactstrap";
import { publish } from "pubsub-js";
import { useParams, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import useToggle from "../../../hooks/useToggle";
import { useUserSearch } from "../../../hooks/useUserSearch";
import IndeterminateButton from "../../IndeterminateButton";
import { setUserInfo } from "../../../reducers/rootReducer";
import { useSearchData } from "../../../hooks/useSearchData";
import { locationQueryModel } from "../SearchProvider";
import { useQueryParams } from "use-query-params";
import { getPageType } from "../../../support/helpers";
import useTrackEvent from "../../../hooks/useTrackEvent";
import SaveSearchForm from "./SaveSearchForm";
import CdnImage from "../../CdnImage";
import { FaTimes } from "react-icons/fa";
import Drawer from "../../Drawer";

export const alertOptions = {
    never: { label: "None", value: "None" },
    daily: { label: "Daily", value: "Daily" },
    weekly: { label: "Weekly", value: "Weekly" },
    monthly: { label: "Monthly", value: "Monthly" },
};

/**
 * Save Search will show the save search button and save the search when clicked.
 * It will then display a drawer with options to update the name and frequency of alerts.
 */
const SaveSearch = ({ location: { pathname }, disabled }) => {
    const dispatch = useDispatch();
    const defaultRange = useSelector((state) => state.search.defaultRange);
    const { trackGTM } = useTrackEvent();
    const [locationQuery] = useQueryParams(locationQueryModel);
    const { search: searchLabel, zip, range = defaultRange } = locationQuery;
    const {
        hasSearched,
        resultsData: results,
        locationData: location,
    } = useSearchData();
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const userID = useSelector((state) => state.root.userSession.userID);
    const isLoggedIn = useSelector((state) => state.root.isLoggedIn);
    const isMobile = useSelector((state) => state.root.isMobile);
    const routeParams = useParams();
    const isState = getPageType(routeParams, "state");
    const firstName = useSelector((state) => state.root.userSession.firstName);
    const nicheName = useSelector(
        (state) => state.root.styling.nicheNamenicheName
    );
    const searchForm = useForm({ mode: "all" });
    const { setValue, reset } = searchForm;

    const {
        isLoading: saveIsLoading,
        execute: executeSave,
        data: userSearchSave,
        error: userSearchSaveError,
        clear,
    } = useUserSearch();
    const [alertData, setAlertData] = useState(undefined);
    const [isUpdating, setIsUpdating] = useState(false);
    const [saveSearchData, setSaveSearchData] = useState();
    const [isOpen, toggleIsOpen] = useToggle(false);
    const [waitingForLogin, toggleWaitingForLogin] = useToggle(false);
    const successTimeoutRef = useRef(null);
    const isDisabled = disabled || !hasSearched;

    const handleClick = () => {
        trackGTM({
            event: "buttonClick",
            action: "click",
            type: "Save Search",
            category: "user_action",
        });

        if (isOpen) {
            toggleIsOpen();
        } else if (isLoggedIn) {
            handleSave();
        } else {
            toggleWaitingForLogin();
            if (isMobile) {
                publish("TOGGLE_MOBILE_DRAWER");
            } else {
                publish("TOGGLE_ACCOUNT_DRAWER");
            }
        }
    };

    const resetSaveSearch = () => {
        setAlertData(undefined);
        clear();
        reset();
        setIsUpdating(false);
        setSaveSearchData();
    };

    const handleSave = async (data) => {
        let search = results?.filters;
        // TODO: The logic for the location search (state) should be moved to the DB.
        if (isState) {
            search = {
                ...search,
                distance_miles: location?.myFilterDistanceMiles,
                zipcode: location?.zipcode || location?.myFilterSearchbox,
            };
            if (range && (searchLabel || zip)) {
                const distanceToLocationLabel = `${range}mi from ${
                    searchLabel || zip || ""
                }`;
                search.name = `${distanceToLocationLabel} ${
                    search?.name || ""
                }`;
            }
        }

        const body = JSON.stringify(
            data
                ? data
                : {
                      uri: pathname,
                      search,
                  }
        );
        setAlertData(undefined);
        await executeSave(
            userID,
            {
                method: isUpdating ? "PATCH" : "POST",
                headers: {
                    "Content-Type": "application/json",
                    sessionId: sessionID,
                },
                body,
            },
            saveSearchData?.more?.id
        );
        if (data) {
            setAlertData({
                color: "success",
                alertText: <>Search updated!</>,
            });
            successTimeoutRef.current = setTimeout(() => {
                resetSaveSearch();
                successTimeoutRef.current = null;
                toggleIsOpen();
            }, 4000);
        }
        trackGTM({
            event: "searchSaved",
            type: "Save Search",
            category: "system_action",
            searchName: search,
        });
    };

    // This effect waits till the user is logged in to attempt the save again.
    useEffect(() => {
        if (waitingForLogin && isLoggedIn) {
            if (isMobile) {
                publish("TOGGLE_MOBILE_DRAWER");
                setIsUpdating(true);
                toggleIsOpen();
            } else {
                publish("TOGGLE_ACCOUNT_DRAWER");
            }
            toggleWaitingForLogin();
            handleSave();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    useEffect(() => {
        if (userSearchSave) {
            if (userSearchSave?.lastInserted?.value) {
                setValue("name", userSearchSave?.lastInserted?.value);
                setSaveSearchData(userSearchSave?.lastInserted);
            }

            if (userSearchSave?.session || userSearchSave?.sessionID) {
                dispatch(
                    setUserInfo(
                        userSearchSave?.session
                            ? userSearchSave.session
                            : userSearchSave
                    )
                );
                if (!isUpdating) {
                    toggleIsOpen();
                    setIsUpdating(true);
                }
            } else {
                setAlertData({
                    color: "danger",
                    alertText: (
                        <>
                            Sorry, we seem to be having issues right now. Please{" "}
                            <Link to="/info/contact-us/">Contact us</Link> to
                            report the issue.
                        </>
                    ),
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSearchSave]);

    // Reset search when the dialog closes
    useEffect(() => {
        if (!isOpen && userSearchSave) {
            resetSaveSearch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        if (userSearchSaveError) {
            setAlertData({
                color: "danger",
                alertText: (
                    <>
                        Sorry, we seem to be having issues right now. Please{" "}
                        <Button
                            className="p-0 d-inline"
                            color="link"
                            onClick={() => handleSave()}
                        >
                            click here to try again.
                        </Button>
                    </>
                ),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSearchSaveError]);

    // Reset search when city serach is cleared
    useEffect(() => {
        const reset = PubSub.subscribe("RESET_SEARCH", () => {
            if (isOpen) {
                toggleIsOpen();
            }
        });
        return () => {
            PubSub.unsubscribe(reset);
        };
    }, [isOpen]);

    return (
        <>
            <IndeterminateButton
                className="btn btn-primary ms-auto me-2 px-2 py-2"
                isLoading={!isUpdating && saveIsLoading}
                onClick={() => handleClick()}
                disabled={isDisabled}
            >
                Save Search
            </IndeterminateButton>
            <Drawer
                isOpen={isOpen}
                toggle={() => toggleIsOpen()}
                headerText={firstName || ""}
            >
                <ModalBody>
                    {saveIsLoading && (
                        <div className="mb-2 text-center">
                            <Spinner size="sm" /> Saving Search
                        </div>
                    )}
                    <SaveSearchForm
                        saveSearchData={saveSearchData}
                        alertData={alertData}
                        handleSave={handleSave}
                        searchForm={searchForm}
                        isLoading={isUpdating && saveIsLoading}
                        resetSaveSearch={resetSaveSearch}
                        alertOptions={alertOptions}
                        successTimeoutRef={successTimeoutRef}
                    />
                </ModalBody>
            </Drawer>
        </>
    );
};

export default withRouter(SaveSearch);
