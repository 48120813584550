import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useQueryParam, StringParam } from "use-query-params";
import RegionTitle from "../../State/StateAccordion/Regions/RegionTitle";
import NicheItemsTitle from "../../State/StateAccordion/NicheItems/NicheItemsTitle";
import ListingsTitle from "../../State/StateAccordion/Listings/ListingsTitle";
import { Spinner } from "reactstrap";
import SectionWrapper from "./SelectionWrapper";
import { useSearchData } from "../../../../hooks/useSearchData";
import { getPageType } from "../../../../support/helpers";
import { pageTypes } from "../../../../reducers/pagesReducer";
import NicheAreasTitle from "../../Niche/NicheAreasTitle";

const SelectionContext = ({ stateId, nicheItemId, agentId, ...props }) => {
    const [mapView, setMapView] = useQueryParam("mapView", StringParam);
    const pageType = pageTypes[getPageType(useParams(), false)];
    const { childNicheItems, listingsPreview } =
        useSelector((state) => state.pages?.[pageType]?.[nicheItemId]) || {};
    const { listingsPreview: agentListings } =
        useSelector((state) => state.pages?.agents?.[agentId]) || {};
    const stateRegions =
        useSelector((state) => state.pages.states?.[stateId]?.stateRegions) ||
        [];
    const isFetchingGis = useSelector((state) => state.search.isFetchingGis);
    const { hasSearched } = useSearchData();
    const hasRegions = stateRegions.length > 0;
    const nichetypePlural =
        useSelector(
            (state) => state.root.styling.nichetypePlural
        ).toLowerCase() || "niches";
    const defaultView = childNicheItems
        ? "areas"
        : hasSearched
        ? "listings"
        : hasRegions
        ? "regions"
        : nichetypePlural;
    const selection = mapView || defaultView;

    return (
        <div {...props}>
            <div className="d-flex">
                {pageType !== "states" ? (
                    <>
                        {childNicheItems && (
                            <SectionWrapper
                                collapsed={selection !== "areas"}
                                onClick={() => setMapView()}
                            >
                                <NicheAreasTitle />
                            </SectionWrapper>
                        )}
                        <SectionWrapper
                            collapsed={selection === "areas"}
                            onClick={() => setMapView("listings")}
                        >
                            <>
                                {isFetchingGis ? (
                                    <div className="d-flex align-items-center">
                                        <Spinner
                                            size="sm"
                                            color="primary"
                                            className="me-2"
                                        />{" "}
                                        Loading listings...
                                    </div>
                                ) : (
                                    <>
                                        <ListingsTitle
                                            initialData={
                                                agentListings || listingsPreview
                                            }
                                            pageType={pageType}
                                        />
                                    </>
                                )}
                            </>
                        </SectionWrapper>
                    </>
                ) : (
                    <>
                        <SectionWrapper
                            collapsed={selection !== nichetypePlural}
                            onClick={() => setMapView(nichetypePlural)}
                        >
                            <NicheItemsTitle stateId={stateId} />
                        </SectionWrapper>
                        {hasRegions && !hasSearched && (
                            <SectionWrapper
                                collapsed={selection !== "regions"}
                                onClick={() => setMapView("regions")}
                            >
                                <RegionTitle />
                            </SectionWrapper>
                        )}
                        {hasSearched && (
                            <SectionWrapper
                                collapsed={selection !== "listings"}
                                onClick={() => setMapView("listings")}
                            >
                                <>
                                    {isFetchingGis ? (
                                        <>
                                            <Spinner
                                                size="sm"
                                                color="primary"
                                                className="me-2"
                                            />{" "}
                                            Loading listings...
                                        </>
                                    ) : (
                                        <ListingsTitle />
                                    )}
                                </>
                            </SectionWrapper>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default SelectionContext;
