/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *      DebouncedInput component
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-16   BL          22320      1            Re-structured files and renamed to index.js
 *
 */
import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import { omit } from "../../support/helpers";
import { useDebounce } from "../../hooks/useDebounce";

const DebouncedInput = (props) => {
    const [inputValue, setInputValue] = useState("");
    const { debounceTime = 300, onChange } = props;
    const debouncedInputValue = useDebounce(inputValue, debounceTime);

    useEffect(() => {
        if (debouncedInputValue) {
            onChange(inputValue);
        }
    }, [debouncedInputValue, inputValue, onChange]);

    const handleChange = (e) => {
        const { value: nextValue } = e.target;
        setInputValue(nextValue);
    };

    return (
        <Input
            {...omit(props, ["debounceTime", "onChange", "value"])}
            value={inputValue}
            onChange={handleChange}
        />
    );
};

export default DebouncedInput;
