import React from "react";
import { useSearchQueryParam } from "../../../hooks/useSearchQueryParam";
import PriceRangeInput from "./PriceRangeInput";

const PriceRange = ({ label }) => {
    const [price_min, setPriceMin] = useSearchQueryParam("price_min");
    const [price_max, setPriceMax] = useSearchQueryParam("price_max");

    return (
        <div className="d-flex align-items-center justify-content-between">
            {label && <div className="text-muted mx-2">{label}</div>}
            <PriceRangeInput
                value={price_min}
                setValue={setPriceMin}
                defaultOptions={[0, 100000, 200000, 300000, 400000, 500000]}
                label="Min"
                suffix="+"
            />
            <div className="text-muted mx-2">to</div>
            <PriceRangeInput
                value={price_max}
                setValue={setPriceMax}
                defaultOptions={[
                    300000,
                    400000,
                    500000,
                    600000,
                    700000,
                    800000,
                    900000,
                    1000000,
                    "Any",
                ]}
                label="Max"
            />
        </div>
    );
};

export default PriceRange;
