import React from "react";
import { Dropdown, DropdownToggle, DropdownMenu, Button } from "reactstrap";
import styled from "styled-components";

const DropdownSearchItem = ({
    isMobile,
    isOpen,
    toggleIsOpen,
    disabled,
    toggleStyle,
    toggleClasses,
    menuStyle,
    filtersHeight,
    displayRight,
    shouldLockFilters,
    reset,
    label,
    children,
    showMenuFooter,
}) => {
    return (
        <Dropdown
            className={isMobile ? "position-static" : ""}
            isOpen={isOpen}
            toggle={toggleIsOpen}
            disabled={disabled}
        >
            <DropDownToggleCaret
                nav
                className={`${toggleStyle} ${toggleClasses || ""} ${
                    isOpen ? "selected" : ""
                }`}
                disabled={disabled}
                caret
            >
                {label}
            </DropDownToggleCaret>
            <DropdownMenu
                className={`${menuStyle}`}
                style={{
                    width: isMobile ? "100%" : "400px",
                    height: filtersHeight,
                }}
                end={displayRight}
            >
                {isOpen && (
                    <>
                        <div
                            className={`p-3 ${
                                shouldLockFilters && isMobile
                                    ? "overflow-auto"
                                    : ""
                            }`}
                            style={{ height: filtersHeight }}
                        >
                            {children(isOpen, toggleIsOpen)}
                        </div>
                        {showMenuFooter && (
                            <div
                                className={`d-flex px-3 py-2 bg-light border-top ${
                                    shouldLockFilters && isMobile
                                        ? "position-fixed w-100"
                                        : ""
                                }`}
                                style={{ bottom: 0 }}
                            >
                                {reset && (
                                    <Button
                                        className="border"
                                        color="light"
                                        onClick={() => {
                                            reset();
                                            toggleIsOpen();
                                        }}
                                    >
                                        Reset
                                    </Button>
                                )}
                                <Button
                                    color="primary"
                                    className="ms-auto"
                                    onClick={() => toggleIsOpen()}
                                >
                                    Done
                                </Button>
                            </div>
                        )}
                    </>
                )}
            </DropdownMenu>
        </Dropdown>
    );
};

const DropDownToggleCaret = styled(DropdownToggle)`
    :after {
        border: 0 !important;
        font-weight: 900;
        font-family: "Font Awesome 5 Free";
        height: 15px;
        width: 15px;
        background-color: var(--bs-primary);
        -webkit-mask: url("/assets/chevron-down-solid.svg") no-repeat 50% 50%;
        mask: url("/assets/chevron-down-solid.svg") no-repeat 50% 50%;
        -webkit-mask-size: cover;
        mask-size: cover;
        margin-top: -2px;
    }
    :hover:after {
        background-color: var(--bs-white);
    }
    &.selected:after {
        -webkit-mask: url("/assets/chevron-up-solid.svg") no-repeat 50% 50%;
        mask: url("/assets/chevron-up-solid.svg") no-repeat 50% 50%;
    }
`;

export default DropdownSearchItem;
