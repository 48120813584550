import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Alert } from "reactstrap";
import { StateMetrics } from ".";
import Fetching from "../../Fetching";
import Breadcrumbs from "../../Breadcrumbs";
import { useSearchData } from "../../../hooks/useSearchData";
import { useQueryParam, StringParam } from "use-query-params";
import { filterNicheItemToIds } from "../../../support/helpers";
import StateAccordion from "./StateAccordion";
import { useNacLocation } from "../../../hooks/useNacLocation";

function Main({ setShouldCluster }) {
    const { state: { stateId } = {} } = useNacLocation();
    const [mapView] = useQueryParam("mapView", StringParam);
    const stateData = useSelector((state) => state.pages.states?.[stateId]);
    const { locationData: location, hasSearched } = useSearchData();
    const { nichetypePlural } = useSelector((state) => state.root.styling);
    const isListingsView =
        mapView === "listings" ||
        (hasSearched && mapView !== nichetypePlural.toLowerCase());
    const { headerLinks, isServiced } = stateData || {};
    const matchingNicheItems = filterNicheItemToIds(
        location?.matchesNicheItems?.diNicheItems || []
    );
    const searchedButNoMatches =
        hasSearched && location && matchingNicheItems.length === 0;

    useEffect(() => {
        setShouldCluster(isListingsView);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isListingsView]);

    return (
        <Fetching waitUntil={stateData}>
            <div className="bg-white" data-testid="state-main">
                <div className="p-3">
                    <Breadcrumbs
                        className="pt-0 d-none d-md-block"
                        crumbs={headerLinks}
                        page="state"
                    />
                    <StateMetrics className="mb-3" data-test="state-title" />
                    {!isServiced || searchedButNoMatches ? (
                        <Alert color="info" className="my-3">
                            {searchedButNoMatches ? (
                                <>
                                    Your search returned no results. Please
                                    update the location or increase the range.
                                </>
                            ) : (
                                <>
                                    {nichetypePlural} for this state coming
                                    soon. Please check the states listed in the
                                    menu or at the bottom of this page for tens
                                    of thousands of accurate lake real estate{" "}
                                    {nichetypePlural.toLowerCase()}.
                                </>
                            )}
                        </Alert>
                    ) : (
                        <StateAccordion />
                    )}
                </div>
            </div>
        </Fetching>
    );
}

export default Main;
