import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import ListingItem from "../ListingItem";
import Curtain from "../Curtain";
import SortOrder from "../Search/SortOrder";
import { useSearchData } from "../../hooks/useSearchData";
import { useQueryParam, NumberParam } from "use-query-params";

/**
 * Renders a paginated listing component with dynamic data and pagination functionality.
 *
 * @param {string} title - The title of the listing component.
 * @param {string} listingItemClasses - The CSS classes for each listing item.
 * @param {object} initialData - The initial data to populate the listing with.
 * @param {boolean} hideSortOrder - Flag to hide the sort order component.
 * @param {boolean} showNicheItem - Flag to show niche items.
 * @param {boolean} appendPageNumberToUrl - Flag to append page number to URL.
 * @param {boolean} isOffmarket - Flag to indicate if the listings are off-market.
 * @return {JSX.Element} The paginated listing component.
 */
const PagnatedListing = ({
    title = undefined,
    listingItemClasses = "col-sm-12 col-md-12 col-lg-4",
    initialData,
    hideSortOrder = false,
    showNicheItem = true,
    appendPageNumberToUrl = true,
    isOffmarket = false,
}) => {
    const [page, setPage] = useQueryParam("page", NumberParam);
    const { pathname, search } = useLocation();
    const isFetching = useSelector((state) => state.search.isFetching);
    const { resultsData } = useSearchData();
    const {
        page_number = 1,
        page_count,
        pages,
        results,
    } = resultsData || initialData || {};
    const history = useHistory();
    const { three: urlPageNumber } = useParams();
    const pageList = pages?.[page_number || 1] || results;
    const isPaginated = page_count && page_count > 1;

    // This might be a little brittle...not quite sure yet.
    // I am checking if the 3rd param is a number and if so, performing the logic.
    // This needs to be done so that we will remove a page number if it is no longer a page.
    useEffect(() => {
        if (appendPageNumberToUrl) {
            if (
                urlPageNumber &&
                !isNaN(parseInt(urlPageNumber)) &&
                urlPageNumber !== page_number.toString()
            ) {
                updatedPathWithPage(undefined);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updatedPathWithPage = (pageToUse) => {
        const pathArray = history.location.pathname.split("/");
        const lastItem = parseInt(pathArray[pathArray.length - 1]);
        let pathname;
        if (!pageToUse) {
            pathname = `${pathArray.slice(0, -1).join("/")}`;
        } else if (lastItem) {
            pathname = `${pathArray.slice(0, -1).join("/")}/${pageToUse}`;
        } else {
            pathname = `${pathArray.join("/")}/${pageToUse}`;
        }
        history.replace({
            pathname,
            state: history.location.state,
        });
    };

    return (
        <Curtain show={isFetching}>
            <div className="container-fluid g-0" data-test="paginated-listings">
                {!pageList || pageList.length === 0 ? (
                    <div>No listings found</div>
                ) : (
                    <>
                        {title && (
                            <div className="row g-0">
                                <div className="col g-0">
                                    <h5>{title}</h5>
                                </div>
                            </div>
                        )}
                        <div className="row  g-0">
                            <div className="col d-flex px-0 mx-1 flex-wrap align-items-center">
                                <div className="me-2">
                                    <div
                                        className="small"
                                        data-test="page-numbers"
                                    >
                                        Page {page_number} of{" "}
                                        {page_count || "1"}
                                    </div>
                                </div>
                                {!hideSortOrder && (
                                    <span className="d-flex ms-auto align-items-center">
                                        <span className="me-1">Sort By:</span>
                                        <SortOrder />
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="row g-0 mt-1">
                            {pageList.map((listing) => (
                                <ListingItem
                                    key={`listing-item-${listing.listingId}`}
                                    listing={listing}
                                    className={`listing-item px-0 mb-2 ${listingItemClasses}`}
                                    showNicheItem={showNicheItem}
                                    isOffmarket={isOffmarket}
                                />
                            ))}
                        </div>
                        {isPaginated && (
                            <Pagnation
                                className="row g-0 justify-content-center"
                                data-test="listings-pagination"
                            >
                                <ReactPaginate
                                    pageCount={page_count}
                                    forcePage={page_number - 1}
                                    pageRangeDisplayed={1}
                                    marginPagesDisplayed={2}
                                    previousLabel={
                                        <FaChevronLeft
                                            style={{ marginLeft: "-2px" }}
                                        />
                                    }
                                    nextLabel={
                                        <FaChevronRight
                                            style={{ marginRight: "-2px" }}
                                        />
                                    }
                                    containerClassName="list-inline d-flex justify-content-center align-items-center"
                                    pageClassName="d-inline text-primary mx-1"
                                    breakClassName="d-inline text-primary mx-1"
                                    activeClassName="d-flex justify-content-center align-items-center border-primary text-primary p-2 selected text-decoration-underline"
                                    activeLinkClassName=""
                                    previousClassName="d-inline me-1 me-md-4"
                                    previousLinkClassName="d-flex justify-content-center align-items-center border rounded-circle bg-light border-primary text-primary p-2"
                                    nextClassName="d-inline ms-1 ms-md-4"
                                    nextLinkClassName="d-flex justify-content-center align-items-center border rounded-circle bg-light border-primary text-primary p-2"
                                    disabledClassName="disabled"
                                    hrefBuilder={(currentPage) => {
                                        const urlParams = new URLSearchParams(
                                            search
                                        );
                                        urlParams.set("page", currentPage);
                                        return `${pathname}?${urlParams.toString()}`;
                                    }}
                                    onPageChange={async ({ selected }) => {
                                        window.scrollTo(0, 0);
                                        const nextPage = selected + 1;
                                        setPage(nextPage);
                                    }}
                                />
                            </Pagnation>
                        )}
                        {initialData?.paginationCreditText &&
                            initialData.paginationCreditText.length > 0 && (
                                <div className="pagination-credits">
                                    {initialData.paginationCreditText.map(
                                        (creditItem, i) => (
                                            <div
                                                key={`credit-item-${i}`}
                                                dangerouslySetInnerHTML={{
                                                    __html: creditItem,
                                                }}
                                            />
                                        )
                                    )}
                                </div>
                            )}
                    </>
                )}
            </div>
        </Curtain>
    );
};

const Pagnation = styled.div`
    .selected a {
        font-weight: bold;
        text-align: center;
        font-size: 20px;
    }
    li:not(.selected) {
        a {
            cursor: pointer;
        }
        a:hover {
            text-decoration: underline;
        }
    }
    li.selected a,
    li.disabled a,
    li.disabled span,
    li.disabled svg {
        cursor: auto;
    }
    .disabled .text-primary,
    .disabled .border-primary {
        color: var(--bs-light-gray) !important;
        border-color: var(--bs-light-gray) !important;
    }
`;

export default PagnatedListing;
