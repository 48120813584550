import React from "react";
import Terrain from "./Terrain";
import Zoom from "./Zoom";

function Controlls(props) {
    return (
        <div {...props} className={`d-flex align-items-end ${props.className}`}>
            <Terrain className="me-2" />
            <Zoom />
        </div>
    );
}

export default Controlls;
