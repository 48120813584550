import React from "react";
import { useSelector } from "react-redux";
import { LuWaves } from "react-icons/lu";

function NicheAreasTitle() {
    const nichetypeSingular = useSelector(
        (state) => state.root.styling.nichetypeSingular
    );

    return (
        <div className="d-flex align-items-center lh-1">
            <LuWaves size="1.5rem" />
            <span className="ms-2">{nichetypeSingular} Areas</span>
        </div>
    );
}

export default NicheAreasTitle;
