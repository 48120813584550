import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import GoogleMap from "./GoogleMap";
import StaticMap from "./StaticMap";
import { getConfigItem } from "../../support/helpers";

const MapWrapper = ({ children, showDynamicOverride = false, ...props }) => {
    const mapHandling = useSelector((state) => state.root.styling?.mapHandling);
    const [showDynamicMap, setShowDynamicMap] = useState(
        showDynamicOverride || mapHandling === "dynamic"
    );
    const mapKey = getConfigItem("REACT_APP_MAP_API_KEY");
    const markers =
        children && children?.length
            ? children.map((child) => `${child.props.lat},${child.props.lng}|`)
            : [];

    useEffect(() => {
        setShowDynamicMap(showDynamicOverride || mapHandling === "dynamic");
    }, [showDynamicOverride, setShowDynamicMap, mapHandling]);

    if (!mapKey) return null;
    return (
        <>
            {mapHandling !== "none" && mapKey && (
                <div className="bg-dark w-100 h-100 ">
                    <Wrapper
                        className={`h-100 ${mapHandling} ${
                            showDynamicMap ? "is-dynamic" : ""
                        }`}
                        onClick={() => {
                            if (mapHandling === "fluid") {
                                setShowDynamicMap(true);
                            }
                        }}
                    >
                        {!showDynamicMap &&
                        (mapHandling === "static" ||
                            mapHandling === "fluid") ? (
                            <StaticMap
                                markers={markers}
                                mapKey={mapKey}
                                mapCenter={props.defaultCenter}
                                zoom={props.defaultZoom}
                                asBg={props.asBg}
                            />
                        ) : (
                            <GoogleMap
                                mapKey={mapKey}
                                defaultCenter={props.defaultCenter}
                                size={props.size}
                                mapHeight={props.dynamicMapHeight}
                                setBounds={props.setBounds}
                            >
                                {children}
                            </GoogleMap>
                        )}
                    </Wrapper>
                </div>
            )}
        </>
    );
};

const Wrapper = styled.div`
    position: relative;
    &.fluid:not(.is-dynamic) {
        &:hover {
            opacity: 0.7;
            &:after {
                position: absolute;
                cursor: pointer;
                left: 50%;
                margin-left: -140px;
                top: 50%;
                margin-top: -20px;
                font-size: 20px;
                font-weight: bold;
                background-color: var(--bs-white);
                padding: 5px;
                content: "Click to interact with the map";
            }
        }
    }
`;

export default MapWrapper;
