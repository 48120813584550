import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Breadcrumb, BreadcrumbItem, Button } from "reactstrap";
import { buildPath } from "../../support/helpers";

/**
 * Displays the websites breadcrumbs
 * @param {string} className classes for breadcrumbs component
 * @param {array} crumbs list of breadcrumbs
 * @param {theme} string light or dark
 * @returns Breadcrumbs component
 */
const Breadcrumbs = ({ className, crumbs, theme = "dark" }) => {
    const history = useHistory();
    const searchHistory = useSelector((state) => state.search.searchHistory);

    const handleClick = (e, path, more) => {
        e.preventDefault();
        history.push(buildPath(path), {
            stateId: more?.stateID || more?.sID || more?.stateCode || more?.id,
            nicheItemId: more?.nicheItemID || more?.id,
        });
    };

    if (!crumbs) return null;
    return (
        <div className={className} data-test="breadcrumbs">
            <StyledBreadcrumbs
                listClassName={`bg-transparent px-0 mb-0 flex-nowrap`}
            >
                {crumbs.map((crumb, i) => {
                    const searchItem = searchHistory[crumb[0].valueLink];
                    let path = crumb[0].valueLink;
                    if (searchItem?.query) path = searchItem.query;
                    return path ? (
                        <BreadcrumbItem
                            className="d-none d-lg-inline-flex"
                            key={`crumb-${i}`}
                            theme={theme}
                            data-test={`breadcrumb-item-${i}`}
                        >
                            <Button
                                color="link"
                                className={`p-0 border-0 text-truncate ${
                                    theme === "light" ? "text-white" : ""
                                }`}
                                href={path}
                                onClick={(e) =>
                                    handleClick(e, path, crumb[0].more)
                                }
                            >
                                {crumb[0].value}
                            </Button>
                        </BreadcrumbItem>
                    ) : (
                        <BreadcrumbItem
                            key={`crumb-${i}`}
                            active
                            theme={theme}
                            className={`d-none d-lg-inline-flex text-truncate ${
                                theme === "light" ? "text-white" : ""
                            }`}
                        >
                            {crumb[0].value}
                        </BreadcrumbItem>
                    );
                })}
            </StyledBreadcrumbs>
        </div>
    );
};

const StyledBreadcrumbs = styled(Breadcrumb)`
    li {
        &:before {
            --bs-breadcrumb-divider: ">";
            color: ${(props) =>
                props.theme === "light"
                    ? "var(--bs-white)"
                    : "var(--bs-black)"};
        }
    }
    @media (max-width: 992px) {
        li:nth-last-child(2) {
            display: inline-flex !important;
            overflow: hidden;
            margin-right: 0.5rem;
            &:before {
                --bs-breadcrumb-divider: "<";
            }
            a {
                text-overflow: ellipsis;
            }
        }
    }
`;

export default Breadcrumbs;
