import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import { useMediaQuery } from "react-responsive";
import { setSelectedMarker } from "../../reducers/mapReducer";
import { useMapContext, setMap, removeMap } from "../../contexts/map";
import useTrackEvent from "../../hooks/useTrackEvent";
import { useQueryParam, NumberParam } from "use-query-params";
import { getPageType } from "../../support/helpers";

/**
 * Wrapper for GoogleMapReact.
 */
const GoogleMap = ({ children, mapKey, defaultCenter, setBounds }) => {
    const [zoomParam, setZoomParam] = useQueryParam("mapZoom", NumberParam);
    const {
        state: { map, maps },
        dispatch: dispatchMap,
    } = useMapContext();
    const dispatch = useDispatch();
    const { trackGTM } = useTrackEvent();
    const selectedMarker = useSelector((state) => state.map.selectedMarker);
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const isListing = getPageType(useParams(), "listing");

    const mapOptions = {
        tilt: 0, // removes the tilt of map when zoomed close to ground satellite view
        rotateControl: false, // removes the rotate controls from map satellite view
    };

    // Removing the map when the component unmounts. This happens when visiting a non-map page.
    useEffect(() => {
        return () => dispatchMap(removeMap());
    }, []);

    useEffect(() => {
        if (map && zoomParam !== undefined) {
            map.setZoom(zoomParam);
        }
    }, [map, zoomParam]);

    useEffect(() => {
        let idleListener, listener;
        if (maps) {
            listener = maps.event.addListener(map, "zoom_changed", function () {
                if (map.systemZoomChange) {
                    // Reset the flag for a system-initiated event
                    map.systemZoomChange = false;
                } else if (!isListing) {
                    setZoomParam(map?.getZoom());
                }
                trackGTM({
                    event: `zoomChange`,
                    action: "zoom",
                    category: "mapInteraction",
                    type: "Zoom Change",
                    zoomLevel: map.getZoom(),
                });
            });
            idleListener = maps.event.addListener(map, "idle", function () {
                // Cleanup ...if for some reason systemZoomChange is true and the map is idle
                // we want to make sure it gets set to false.
                setTimeout(() => {
                    map.systemZoomChange = false;
                }, 500);
            });
        }
        return () => {
            if (listener) listener.remove();
            if (idleListener) idleListener.remove();
        };
    }, [maps, map, isMobile, isListing, dispatch]);

    useEffect(() => {
        let listener;
        if (maps && selectedMarker) {
            listener = maps.event.addListener(map, "click", function () {
                dispatch(setSelectedMarker(undefined));
            });
        }
        return () => {
            if (listener) listener.remove();
        };
    }, [maps, map, selectedMarker, dispatch]);

    if (!mapKey) return null;
    return (
        <div className="w-100 h-100 position-relative" style={{ zIndex: 9 }}>
            <GoogleMapReact
                bootstrapURLKeys={mapKey ? { key: mapKey } : undefined}
                defaultCenter={defaultCenter}
                defaultZoom={7}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                    map.setOptions({ zoomControl: false });
                    dispatchMap(setMap(map, maps));
                }}
                onChange={({ bounds }) => {
                    setBounds([
                        bounds.nw.lng,
                        bounds.se.lat,
                        bounds.se.lng,
                        bounds.nw.lat,
                    ]);
                }}
                options={mapOptions}
            >
                {children}
            </GoogleMapReact>
        </div>
    );
};

export default GoogleMap;
