import React from "react";
import { components } from "react-select";
import useCurrencyMask from "./useCurrencyMask";

const CurrencyInput = ({ suffix, ...props }) => {
    const { onInput, onKeyDown } = useCurrencyMask(suffix);
    // We want to use a type of tel because it shows the number keyboard
    // on mobile while also allowing characters other than numbers
    return (
        <components.Input
            {...props}
            type="tel"
            inputMode="numeric"
            onInput={onInput}
            onKeyDown={onKeyDown}
        />
    );
};

export default CurrencyInput;
