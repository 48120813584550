import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Badge, Button } from "reactstrap";
import { FaPhone } from "react-icons/fa";
import useTrackEvent from "../../../hooks/useTrackEvent";
import { useStickyAgent } from "../../../hooks/useStickyAgent";
import Fetching from "../../Fetching";
import Contact from "../../Contact";
import DiItems from "../../DisplayItems/DiItems";
import PagnatedListing from "../../PagnatedListing";
import Breadcrumbs from "../../Breadcrumbs";
import PageTitle from "../PageTitle/PageTitle";
import EditorWrapper from "../../Editor/EditorWrapper";
import { useGetAgent } from "../../../hooks/agent";
import { addAgentPage } from "../../../reducers/pagesReducer";
import { useNacLocation } from "../../../hooks/useNacLocation";
import styled from "styled-components";

/**
 * Returns the content for the Agent page, such as
 * the agent info, contact form, and their listings.
 */
const Main = () => {
    const dispatch = useDispatch();
    const agents = useSelector((state) => state.pages.agents);
    const isEntitled = useSelector((state) => state.root.isEntitled);
    const { state: { agentId: stateAgentId, stateId, nicheItemId } = {} } =
        useNacLocation();
    const agentId =
        stateAgentId ||
        (typeof window !== "undefined" &&
            window !== null &&
            window?.locationState?.agentId);
    const { execute } = useStickyAgent();
    const { trackEvent, trackGTM } = useTrackEvent();
    const { execute: getAgent } = useGetAgent();
    const agent = agents?.[agentId];
    const { headerLinks, agentInfo, agentAreasServed } = agent?.data || {};
    const {
        agent_name,
        agentTitle_part1,
        agentTitle_part2,
        brokerageName,
        agent_pic,
        is_active,
        agent_short_bio,
        agent_website_phone,
    } = agentInfo || {};
    const internalAgentID = agent_name?.more?.internalAgentID;
    const isActive = is_active?.value;
    const handleClick = async () => {
        await execute(internalAgentID);
    };

    return (
        <Fetching waitUntil={agent}>
            <div className="p-3 bg-white" data-testid="agent-main">
                <Breadcrumbs crumbs={headerLinks} />
                <div className="d-flex align-items-center">
                    <div className="text-muted my-0 mt-2 h4">
                        {`${agentTitle_part1?.value} ${agentTitle_part2?.value}`}
                    </div>
                    {isEntitled && !isActive ? (
                        <Badge className="ms-auto" size="sm" color="warning">
                            Inactive
                        </Badge>
                    ) : null}
                </div>
                <PageTitle
                    className="mb-2"
                    data-test="agent-title"
                    title={agent_name?.value}
                    subtitle={brokerageName?.value}
                    titleClass="mb-0"
                    subtitleClass="text-muted my-0 h5"
                    titleStyle={{ lineHeight: "2rem", marginLeft: "-1px" }}
                />
                <div className="row g-0">
                    <div className="col-12 col-lg-6 g-0">
                        <div className="mb-1 me-0 me-lg-3">
                            {agent_pic?.imageUrl && (
                                <AgentImage
                                    src={
                                        agent_pic?.imageUrl ||
                                        "/assets/no-image-found-360x260.png"
                                    }
                                    onError={(e) =>
                                        (e.target.src =
                                            "/no-image-found-360x260.png")
                                    }
                                    alt={agent_name?.value || "Agent"}
                                    data-test="agent-photo"
                                />
                            )}
                        </div>
                        {agent_website_phone?.value && (
                            <div className="mb-3" data-test="agent-phone">
                                <Button
                                    color="link"
                                    className="d-flex align-items-center m-0 px-0"
                                    href={`tel:${agent_website_phone.value}`}
                                    onClick={() => {
                                        trackGTM({
                                            event: `phoneClick`,
                                            action: "click",
                                            type: "Agent",
                                            category: "contact",
                                            button_label:
                                                agent_website_phone?.value,
                                            value: agent_website_phone?.value,
                                            ...agent,
                                        });
                                        trackEvent("ACTIONS", {
                                            CATEGORY: "event",
                                            ASSOCIATE: "Agent Phone",
                                            ACTION: "click",
                                            SOURCE_INFO: {
                                                eventLabel: agent_name?.value,
                                                eventValue:
                                                    agent_website_phone.value,
                                            },
                                        });
                                    }}
                                >
                                    <div>
                                        <FaPhone
                                            className="me-2"
                                            size="12"
                                            style={{ marginTop: "-4px" }}
                                        />
                                    </div>
                                    <div>{agent_website_phone.value}</div>
                                </Button>
                            </div>
                        )}
                        {agentAreasServed && (
                            <DiItems
                                wrapperClsses="ms-2"
                                items={agentAreasServed}
                                excludeIdsFromState={["agentId"]}
                                valueLinkClasses="pl-3"
                                clickHandler={handleClick}
                                linkQueryParams={`?lhra_id=${internalAgentID}`}
                            />
                        )}
                    </div>
                    <div
                        className="col-12 col-lg-6 g-0"
                        data-test="agent-contact-form"
                    >
                        <Contact agentId={internalAgentID} />
                    </div>
                </div>
                <EditorWrapper
                    category="AGENTS"
                    item="Short-Bio"
                    id={agentId}
                    editorName="RichTextEditor"
                    defaultContent={agent_short_bio?.value}
                    publishCallback={async () => {
                        const response = await getAgent(
                            agentId,
                            stateId,
                            nicheItemId,
                            { gispreview: true }
                        );
                        dispatch(addAgentPage({ id: agentId, data: response }));
                    }}
                >
                    <DiItems
                        items={[agent_short_bio]}
                        wrapperClsses="d-inline-block mt-3"
                        labelClasses="d-none"
                        valueIsHtml={true}
                        dataTest="agent-short-bio"
                    />
                </EditorWrapper>
                <div className="row g-0">
                    <div className="mb-2" data-test="agent-listings-title">
                        <h4 className="mb-0 mt-1 title">My Listings</h4>
                    </div>
                    <PagnatedListing
                        title=""
                        initialData={agent?.listingsPreview}
                        initialFilters={{ agent_id: agentId }}
                        listingItemClasses="col-12 col-sm-6 g-0"
                        appendPageNumberToUrl={false}
                    />
                </div>
            </div>
        </Fetching>
    );
};

const AgentImage = styled.img`
    max-width: 100%;
    @media (max-width: 576px) {
        max-height: 175px;
    }
`;

export default Main;
