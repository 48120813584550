import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const AdminEditorButtons = ({
    defaultContent,
    execute,
    setContent,
    setIsEditing,
    userActions,
    ...props
}) => {
    const has = (value) => userActions.includes(value);
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div {...props}>
            <div className="d-flex">
                <div className={`d-flex w-100 hiddenButtons`}>
                    {(has("CREATE") || has("EDIT")) && (
                        <Button
                            className="ms-0 me-2"
                            color="primary"
                            onClick={() => setIsEditing(true)}
                        >
                            Edit
                        </Button>
                    )}
                    {has("2REVIEW") && (
                        <Button
                            color="primary"
                            onClick={async (e) => {
                                execute(e, { method: "POST" }, "2REVIEW");
                            }}
                        >
                            Mark for Review
                        </Button>
                    )}
                    {has("PUBLISH") && (
                        <Button
                            color="primary"
                            onClick={async (e) => {
                                execute(e, { method: "POST" }, "PUBLISH");
                            }}
                        >
                            Publish
                        </Button>
                    )}
                    {has("DELETE") && (
                        <Button
                            color="danger"
                            className="py-1 px-4 ms-auto me-0"
                            onClick={(e) => {
                                setModalOpen(true);
                            }}
                        >
                            Delete
                        </Button>
                    )}
                </div>
            </div>
            <Modal
                modalClassName=""
                centered
                isOpen={modalOpen}
                toggle={() => setModalOpen(false)}
            >
                <ModalHeader>Delete Draft</ModalHeader>
                <ModalBody>
                    <div>Are you sure you want to delete?</div>
                    <div className="d-flex">
                        <Button
                            color="link"
                            className="py-1 px-4 mx-1 ms-auto"
                            onClick={() => setModalOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="danger"
                            className=""
                            onClick={async (e) => {
                                execute(e, { method: "DELETE" });
                                setContent(defaultContent);
                                setModalOpen(false);
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default AdminEditorButtons;
