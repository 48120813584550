import React from "react";
import DiItems from "../DisplayItems/DiItems";

const GroupedFeatures = ({ data }) => (
    <div className="row features-grouped g-0">
        {data &&
            data.map((group, i) => (
                <div
                    key={`grouped-feature-${i}`}
                    className="col-md-4 col-sm-12 mb-2"
                >
                    <div className="h4">{group.name}</div>
                    <div className="d-flex flex-wrap d-md-block">
                        <DiItems
                            wrapperClsses="mb-2 mx-2 mx-md-0"
                            items={group.items}
                            suffixIsHtml={true}
                            noLabelValueClasses="fw-bold"
                            labelSuffixClasses="ms-3"
                        />
                    </div>
                </div>
            ))}
    </div>
);

export default GroupedFeatures;
