import React from "react";
import { useMapContext } from "../../contexts/map";
import { formatCurrency, nFormatter } from "../../support/helpers";

const InfoWindow = ({ marker, selected, showPrice = false }) => {
    const { state: map } = useMapContext();
    return (
        <>
            {marker?.type === "gisDefault" && marker?.displayName && (
                <div className="position-absolute info">
                    <div className="bg-white p-2 w-100">
                        <h6 className="d-block mb-0">{marker?.displayName}</h6>
                    </div>
                </div>
            )}
            {(marker?.type === "region" || marker?.type === "parent") &&
                selected && (
                    <div className="position-absolute info">
                        <div className="bg-white p-2 w-100">
                            <h6 className="d-block mb-0">
                                {marker.displayName || marker.value}
                            </h6>
                        </div>
                    </div>
                )}
            {marker?.type === "niche" && selected && (
                <div className="position-absolute info">
                    <div className="bg-white p-2">
                        <h6 className="d-block mb-0">
                            {marker.displayName || marker.value}
                        </h6>
                        {marker.distance && <div>{marker.distance} miles</div>}
                        <div>
                            {marker?.listings && marker?.listings > 0
                                ? `Listings: ${marker.listings}`
                                : "No Listings"}
                        </div>
                    </div>
                </div>
            )}
            {marker?.type === "listing" && (
                <>
                    {!selected ? (
                        <>
                            {map?.zoom && map?.zoom >= 11 && showPrice && (
                                <div className="text-center position-absolute bg-white rounded p-1 info">
                                    ${nFormatter(marker?.price)}
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="position-absolute info listing">
                            <div className="bg-white p-1 w-100 row m-0">
                                <div className="col-4 p-0 me-1">
                                    <img
                                        width="100%"
                                        src={
                                            marker?.image ||
                                            "/assets/no-image-found-360x260.png"
                                        }
                                        alt="Listing"
                                        onError={(e) =>
                                            (e.target.src =
                                                "/no-image-found-360x260.png")
                                        }
                                    />
                                </div>
                                <div className="col p-0">
                                    {marker?.price && (
                                        <strong>
                                            {formatCurrency(marker.price)}
                                        </strong>
                                    )}
                                    <div>
                                        {
                                            marker?.bedrooms ? (
                                                <>
                                                    {marker?.bedrooms && (
                                                        <span className="me-2">
                                                            {marker.bedrooms} Bd
                                                        </span>
                                                    )}
                                                    {marker?.bathrooms && (
                                                        <span className="me-2">
                                                            {marker.bathrooms}{" "}
                                                            Ba
                                                        </span>
                                                    )}
                                                </>
                                            ) : (
                                                <span className="me-2">
                                                    {marker?.acreage?.readable
                                                        ? marker.acreage
                                                              .readable
                                                        : "Lot"}
                                                </span>
                                            ) //TODO: Get acreage sent over in GIS response
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default InfoWindow;
