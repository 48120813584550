import React from "react";
import { BsFillHouseFill } from "react-icons/bs";
import { useSearchData } from "../../../../../hooks/useSearchData";
import { makePlural } from "../../../../../support/helpers";

const ListingsTitle = ({ initialData = {}, pageType }) => {
    const { resultsData } = useSearchData();
    const { total_count, results } = resultsData || initialData || {};

    return (
        <div className="d-flex align-items-center align-middle lh-1">
            <BsFillHouseFill size="1.5rem" style={{ marginTop: "-2px" }} />
            <span className="ms-2">
                {pageType === "listings" ||
                total_count === null ||
                total_count === undefined
                    ? ""
                    : total_count}{" "}
                {makePlural(
                    "Listing",
                    "Listings",
                    pageType === "listings" ? 1 : results
                )}
            </span>
        </div>
    );
};

export default ListingsTitle;
