import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import { isMobile as touchDevice } from "react-device-detect";
import GalleryImage from "./GalleryImage";

/**
 * React multi-carousel re-implemented for the image carousel
 * @param {array} images - array of image objects
 * @param {function} onClick - function to call on click
 * @returns React component
 */
const ImageCarousel = ({ images, onClick }) => {
    const [currentSlide, setCurrentSlide] = useState(1);
    return (
        <div className="position-relative">
            <Carousel
                swipeable={touchDevice}
                draggable={!touchDevice}
                arrows={true}
                responsive={{
                    all: {
                        breakpoint: { max: 4000, min: 0 },
                        items: 1,
                    },
                }}
                ssr={true} // means to render carousel on server-side.
                autoPlay={false}
                shouldResetAutoplay={false} // this is dumb, but it prevents the autoplay from starting after you interact with the slider
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                deviceType={"all"}
                sliderClass="align-items-stretch"
                beforeChange={(slideNumber) => setCurrentSlide(slideNumber + 1)}
            >
                {images.map((image, i) => (
                    <GalleryImage
                        key={`gallary-carouse-${i}`}
                        src={image.uri}
                        alt={image.description}
                        title={image.description}
                        onClick={() => {
                            if (onClick) onClick();
                        }}
                    />
                ))}
            </Carousel>
            <div
                className="position-absolute badge text-white py-1 px-2"
                style={{
                    backgroundColor: "rgba(0,0,0,.7)",
                    bottom: "5px",
                    right: "5px",
                }}
            >
                {currentSlide} of {images.length}
            </div>
        </div>
    );
};

export default ImageCarousel;
