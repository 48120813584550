import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Drawer from "../Drawer";
import AgentInfo from "../AgentInfo";
import Contact from "../Contact";
import Loading from "../Loading";
import useToggle from "../../hooks/useToggle";
import useTrackEvent from "../../hooks/useTrackEvent";
import { useFeaturedAgent } from "../../hooks/useFeaturedAgent";
import Horizontal from "./Horizontal";
import {
    setFeaturedAgent,
    addAgent,
} from "../../reducers/featuredAgentReducer";
import { isEmptyObject } from "../../support/helpers";
import { ModalBody } from "reactstrap";
import { useNacLocation } from "../../hooks/useNacLocation";

function FeaturedAgent({
    servicingInfo = {},
    byBrokerAgentID,
    dataTest = "featured-agent",
    ...props
}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        pathname,
        state: {
            stateId: state_code,
            nicheItemId,
            developmentId,
            listingId,
        } = {},
    } = useNacLocation();
    const agentsById = useSelector((state) => state.agents.byId);
    const { execute, isLoading } = useFeaturedAgent();
    const [agent, setAgent] = useState(undefined);
    const { trackEvent, trackGTM } = useTrackEvent();
    const [contactType, setContactType] = useState(null);
    const [modalIsOpen, toggleModal] = useToggle(false);
    const { agentName } = agent || {};
    const { agentID, multiAgent } = servicingInfo;

    useEffect(() => {
        let query, fetchData;
        // !servicingInfo will allow this to function the "original" way
        if (
            multiAgent ||
            (agentID && byBrokerAgentID > 0 && byBrokerAgentID !== agentID) ||
            isEmptyObject(servicingInfo)
        ) {
            if (listingId) {
                query = { listingID: listingId };
            } else if (nicheItemId) {
                query = {
                    niche_item_id: nicheItemId,
                    development_id: developmentId || undefined,
                };
            } else {
                query = { state_code };
            }
        } else if (agentID) {
            let agentData;
            if (agentsById[agentID]) {
                if (nicheItemId) {
                    agentData = agentsById[agentID].find(
                        (data) =>
                            data?.agentName?.more?.nicheItemID === nicheItemId
                    ); // should be on the niche item page and must have the agent data for this niche item.
                } else {
                    agentData = agentsById[agentID]?.[0]; // prob. on the listing page and we dont need the niche specific agent.
                }
            }
            if (agentData) {
                setAgent(agentData);
            } else {
                query = {
                    type: "ByID",
                    broker_agent_id: agentID,
                    niche_item_id: nicheItemId,
                };
            }
        } else if (!multiAgent && byBrokerAgentID > 0) {
            // if an agent has a listing on a niche item they do not service.
            query = {
                type: "ByID",
                broker_agent_id: byBrokerAgentID,
                niche_item_id: nicheItemId,
            };
        }
        if (query) {
            fetchData = async () => {
                const agentData = await execute(query);
                if (agentData) {
                    dispatch(addAgent(agentData));
                    setAgent(agentData);
                }
            };
        }
        if (fetchData) fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, agentID, multiAgent, byBrokerAgentID]);

    useEffect(() => {
        if (agent) {
            dispatch(setFeaturedAgent(agent));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, setFeaturedAgent, agent]);

    const handleLink = (e, agentId, path) => {
        e.preventDefault();
        const { stateId, nicheItemID: nicheItemId } = agentName?.more;
        history.push(path, { agentId, stateId, nicheItemId });
    };

    const handleContact = (contactType) => {
        setContactType(contactType);
        toggleModal();
        trackGTM({
            event: `formContact`,
            action: "click",
            category: "contact",
            type: contactType,
            ...agentName,
        });
        trackEvent("ACTIONS", {
            CATEGORY: "event",
            ASSOCIATE: "Contact Start",
            ACTION: "click",
            SOURCE_INFO: {
                eventLabel: "Featured Agent",
                eventValue: agentName?.more?.id,
            },
        });
    };

    // TODO: Will need to determine if the agent is active
    // SHould use something like in_active or inActive

    return (
        <>
            {isLoading && <Loading />}
            {agent && agent?.agentName && !isLoading && (
                <div data-test={dataTest}>
                    <Horizontal
                        {...agent}
                        {...props}
                        handleContact={handleContact}
                        handleLink={handleLink}
                    />
                    <Drawer
                        isOpen={modalIsOpen}
                        toggle={() => toggleModal()}
                        headerText={`Contact ${agentName?.value}`}
                    >
                        <ModalBody>
                            <AgentInfo agent={agent} />
                            {contactType && (
                                <Contact
                                    contactType={contactType}
                                    agentId={agentName?.more?.id}
                                />
                            )}
                        </ModalBody>
                    </Drawer>
                </div>
            )}
        </>
    );
}

export default FeaturedAgent;
