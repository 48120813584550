import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ListingChildToggle } from "../MapSidebar";
import NicheItems from "./NicheItems";
import PagnatedListing from "../../PagnatedListing";
import { getPageType } from "../../../support/helpers";
import { pageTypes } from "../../../reducers/pagesReducer";
import { useQueryParam, StringParam } from "use-query-params";
import { useNacLocation } from "../../../hooks/useNacLocation";

const NicheListingTabs = ({ isOffmarket }) => {
    const { state: { nicheItemId, developmentId } = {} } = useNacLocation();
    const [mapView, setMapView] = useQueryParam("mapView", StringParam);
    const isListingsView = mapView === "listings";
    const pageType = pageTypes[getPageType(useParams(), false)];
    const id = pageType === "developments" ? developmentId : nicheItemId;
    const { childNicheItems, urlPath, listingsPreview } =
        useSelector((state) => state.pages?.[pageType]?.[id]) || {};

    return (
        <>
            {childNicheItems && (
                <div className="mt-3">
                    <ListingChildToggle nicheListings={listingsPreview} />
                </div>
            )}
            <div className="mt-3">
                {childNicheItems && !isListingsView ? (
                    <NicheItems
                        columnCount={1}
                        items={childNicheItems.dILinks.map((dataItem) => ({
                            ...dataItem,
                            value: dataItem.value,
                        }))}
                    />
                ) : (
                    <PagnatedListing
                        title=""
                        paginationUrl={urlPath}
                        initialData={listingsPreview}
                        listingItemClasses="col-12 col-sm-6"
                        isOffmarket={isOffmarket}
                        hideSortOrder={isOffmarket}
                    />
                )}
            </div>
        </>
    );
};

export default NicheListingTabs;
