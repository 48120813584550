import React from "react";
import { useSelector } from "react-redux";
import { AccordionItem, AccordionBody } from "reactstrap";
import { StateNicheList } from "../../";
import { useSearchData } from "../../../../../hooks/useSearchData";
import NicheItemsTitle from "./NicheItemsTitle";
import StateAccordionHeader from "../StateAccordionHeader";

function AccordionNicheItems({ id, stateId, isOpen }) {
    const stateData = useSelector((state) => state.pages.states?.[stateId]);
    const { locationData: location, hasSearched } = useSearchData();
    const { nicheItemsPreviewInfo } = stateData || {};
    const nicheList =
        hasSearched && location?.matchesNicheItems
            ? (location?.matchesNicheItems?.diNicheItems || []).filter(
                  (item) => !item?.more?.hasChildren
              )
            : nicheItemsPreviewInfo;
    return (
        <AccordionItem>
            <StateAccordionHeader targetId={id} isOpen={isOpen}>
                <NicheItemsTitle stateId={stateId} />
            </StateAccordionHeader>
            <AccordionBody accordionId={id}>
                <StateNicheList nicheList={nicheList} />
            </AccordionBody>
        </AccordionItem>
    );
}

export default AccordionNicheItems;
