// Inspired heavily from
// https://medium.com/outsystems-experts/javascript-events-unmasked-how-to-create-an-input-mask-for-mobile-fc0df165e8b2

const useCurrencyMask = (suffix = "") => {
    let oldValue, oldCursor, oldKeyCode;

    const regex = new RegExp(/[0-9]*/g);

    const mask = (value) =>
        `$${new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(
            value.replace(".", "").replace(",", "").replace(/\D/g, "")
        )} ${suffix}`;

    const unmask = (value) => value.replace(new RegExp(/[^\d]/, "g"), ""); // Remove every non-digit character

    const checkSeparator = (position, interval) =>
        Math.floor(position / (interval + 1));

    const onKeyDown = (e) => {
        const { target, keyCode } = e;
        oldValue = target.value;
        oldCursor = target.selectionEnd;
        oldKeyCode = keyCode;
    };

    const onInput = (e) => {
        var el = e.target,
            newCursorPosition,
            newValue = unmask(el.value);

        if (newValue.match(regex)) {
            // is backspace
            if (oldKeyCode === 8) newValue = newValue.slice(0, -1);

            newValue = mask(newValue);
            newCursorPosition =
                oldCursor -
                checkSeparator(oldCursor, 4) +
                checkSeparator(
                    oldCursor + (newValue.length - oldValue.length),
                    4
                ) +
                (unmask(newValue).length - unmask(oldValue).length);

            if (newValue !== "") {
                el.value = newValue;
            } else {
                el.value = "";
            }
        } else {
            el.value = oldValue;
            newCursorPosition = oldCursor;
        }
    };

    return { onKeyDown, onInput };
};

export default useCurrencyMask;
