import React from "react";
import Divider from "../Divider";

const Metric = ({ label, value }) => (
    <Divider>
        <span className="h6 me-1">{value}</span>
        <span className="text-uppercase small">{label}</span>
    </Divider>
);

export default Metric;
