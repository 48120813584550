import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import useToggle from "../../../hooks/useToggle";
import { useMicrocontent } from "../../../hooks/useMicrocontent";
import draftToHtml from "draftjs-to-html";
import Overview from "../../features/Overview";
import Default from "../../features/Default";
import FeatureList from "../../features/FeatureList";

export const useEditor = ({
    defaultContent,
    initialIsEditing,
    initialUserActions,
    initialHideEditor,
    category,
    item,
    id,
    publishCallback,
}) => {
    const {
        execute,
        data,
        isLoading: isMicroLoading,
        error: microContentError,
    } = useMicrocontent();
    const mode = useSelector((state) => state.root.mode);
    const [isEditing, setIsEditing] = useToggle(initialIsEditing);
    const [isFetching, setIsFetching] = useToggle(false);
    const [content, setContent] = useState(defaultContent);
    const [hideEditor, setHideEditor] = useState(initialHideEditor);
    const userActions = initialUserActions ||
        data?.allowedUserActions || ["VIEW"];
    const [alertText, setAlertText] = useState();
    const has = (value) => userActions.includes(value);
    const [showUnsupportedWarning, setShowUnsupportedWarning] = useState(false);
    const unsupportedHtmlTags = ["<iframe", "<video", "<img", "<canvas"];

    useEffect(() => {
        let fetchMicrocontent;
        if (mode === "edit" && has("VIEW") && !isFetching) {
            fetchMicrocontent = async () => {
                setIsFetching(true);
                const response = await execute(category, item, id, {
                    method: "GET",
                });
                if (response?.requestStatus === "FORBIDDEN") {
                    setHideEditor(true);
                } else {
                    setHideEditor(false);
                    if (response?.requestStatus !== "Success")
                        setAlertText("Oops, something went wrong!");
                }
                const mcTypes = [
                    "blocks",
                    "overview",
                    "information",
                    "resources",
                    "restrictions",
                ];
                if (
                    response?.microContent &&
                    mcTypes.some((key) => key in response.microContent)
                ) {
                    setContent(response.microContent);
                }

                setIsFetching(false);
            };
        }
        if (fetchMicrocontent) fetchMicrocontent();
    }, [mode, category, item, id, has, isFetching, execute]);

    useMemo(() => {
        if (typeof content === "string") {
            unsupportedHtmlTags.forEach((tagName) => {
                if (content.includes(tagName)) {
                    setShowUnsupportedWarning(true);
                }
            });
        } else if (showUnsupportedWarning) {
            setShowUnsupportedWarning(false);
        }
    }, [content, showUnsupportedWarning]);

    useEffect(() => {
        if (microContentError) setAlertText("Oops, something went wrong!");
    }, [microContentError]);

    const save = useCallback(
        async (data) => {
            const response = await execute(category, item, id, {
                method: "PUT",
                body: JSON.stringify(data),
            });
            if (response?.statusCode == 400 && response?.message) {
                setAlertText(response.message);
            } else if (response?.requestStatus !== "Success") {
                setAlertText("Oops, something went wrong!");
            } else {
                setContent(data);
                setIsEditing(false);
            }
        },
        [category, item, id, execute, setAlertText, setContent, setIsEditing]
    );

    const adminBtnsExecute = useCallback(
        async (event, settings, action = "") => {
            event.stopPropagation();
            const response = await execute(
                category,
                item,
                id,
                settings,
                action
            );
            if (!response || response?.requestStatus !== "Success") {
                setAlertText("Oops, something went wrong!");
            }
            if (action === "PUBLISH" && response?.requestStatus === "Success") {
                publishCallback(id, content, item);
            }
            setIsEditing(false);
        },
        [category, item, id, publishCallback, setAlertText, setIsEditing]
    );

    return {
        execute,
        hideEditor,
        alertText,
        setAlertText,
        showUnsupportedWarning,
        isEditing,
        setIsEditing,
        content,
        setContent,
        has,
        execute,
        data,
        isMicroLoading,
        userActions,
        renderContent,
        save,
        adminBtnsExecute,
    };
};

const renderContent = (content, children) => {
    if (content?.blocks) {
        return (
            <div dangerouslySetInnerHTML={{ __html: draftToHtml(content) }} />
        );
    }
    if (content?.overview) {
        return <Overview data={content?.overview || content} />;
    }
    if (content?.information) {
        return <Default data={content?.information || content} />;
    }
    if (content?.resources) {
        return <FeatureList data={content?.resources || content} />;
    }
    if (content?.restrictions) {
        return <FeatureList data={content?.restrictions || content} />;
    }
    return children;
};
