import React from "react";
import { useSelector } from "react-redux";
import { LuWaves } from "react-icons/lu";
import { useSearchData } from "../../../../../hooks/useSearchData";
import { makePlural } from "../../../../../support/helpers";

function NicheItemsTitle({ stateId }) {
    const nichetypeSingular = useSelector(
        (state) => state.root.styling.nichetypeSingular
    );
    const nichetypePlural =
        useSelector((state) => state.root.styling.nichetypePlural) || "niches";
    const stateData = useSelector((state) => state.pages.states?.[stateId]);
    const { locationData: location, hasSearched } = useSearchData();

    const { nicheItemsPreviewInfo } = stateData || {};
    const nicheList =
        hasSearched && location?.matchesNicheItems
            ? (location?.matchesNicheItems?.diNicheItems || []).filter(
                  (item) => !item?.more?.hasChildren
              )
            : nicheItemsPreviewInfo;
    return (
        <div className="d-flex align-items-center lh-1">
            <LuWaves size="1.5rem" />
            <span className="ms-2">
                {nicheList?.length}{" "}
                {makePlural(
                    nichetypeSingular,
                    nichetypePlural,
                    nicheList?.length
                )}
            </span>
        </div>
    );
}

export default NicheItemsTitle;
