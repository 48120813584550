import React from "react";
import { useSearchQueryParam } from "../../hooks/useSearchQueryParam";
import { Button } from "reactstrap";
import ToggleButton from "./ToggleButton";

const Waterfront = ({ toggleIsOpen, isToggle }) => {
    const [waterfront, setWaterfront] = useSearchQueryParam("waterfront");

    return (
        <>
            {isToggle ? (
                <ToggleButton
                    value={waterfront}
                    onToggle={() => setWaterfront(!waterfront ? 1 : null)}
                />
            ) : (
                <>
                    <Button
                        className="d-block w-100 my-1"
                        color={waterfront ? "light" : "primary"}
                        onClick={() => {
                            setWaterfront();
                            toggleIsOpen();
                        }}
                    >
                        All
                    </Button>
                    <Button
                        className="d-block w-100 my-1"
                        color={waterfront ? "primary" : "light"}
                        onClick={() => {
                            setWaterfront(1);
                            toggleIsOpen();
                        }}
                    >
                        Tagged as Waterfront
                    </Button>
                </>
            )}
        </>
    );
};

export default Waterfront;
