import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import { isMobile } from "react-device-detect";
import SearchItem from "./SearchItem";
import AssemblyProvider from "../AssemblyProvider";
import { useSearchQueryParam } from "../../hooks/useSearchQueryParam";
import useTrackEvent from "../../hooks/useTrackEvent";
import { getPageType } from "../../support/helpers";

const SortOrder = ({ className }) => {
    const routeParams = useParams();
    const isState = getPageType(routeParams, "state");
    const isFetching = useSelector((state) => state.search.isFetching);
    const [sortOrder, setSortOrder] = useSearchQueryParam("sort_order");
    const { trackGTM } = useTrackEvent();

    // Filtering options. WHen you are on the State page, we want to remove the Featured option. On all other pages Distance needs to be removed.
    const filterOptions = (initialOptions) => {
        const filterValue = isState ? "Featured" : "DistanceAsc";
        initialOptions.splice(
            initialOptions.findIndex((item) => item.value === filterValue),
            1
        );
        return initialOptions;
    };

    return (
        <AssemblyProvider group="USER_SEARCH" name="USER_SEARCH">
            {(assembly) => {
                if (!assembly) return null;
                const assemblyItem = assembly.find(
                    (assemblyItem) => assemblyItem.id === "sort_order"
                );
                const options = filterOptions([
                    ...assemblyItem?.listInfo?.list,
                ]);
                const selected =
                    options.find((item) => item.value === sortOrder) ||
                    options[0];
                return (
                    <SearchItem
                        className={className}
                        label={selected.label}
                        showMenuFooter={false}
                        toggleClasses="py-0"
                        disabled={isFetching !== undefined}
                        isMobile={isMobile}
                        useTooltiip={false}
                    >
                        {(isOpen, toggleIsOpen) => (
                            <div className="flex-grow-1">
                                {options.map((option, i) => (
                                    <Button
                                        key={`option-${option.label
                                            .replace(" ", "-")
                                            .toLowerCase()}`}
                                        className="d-block w-100 my-1"
                                        color={
                                            option.value === selected.value
                                                ? "primary"
                                                : "light"
                                        }
                                        onClick={async () => {
                                            setSortOrder(option.value);
                                            trackGTM({
                                                event: `userSearch`,
                                                action: "filter",
                                                type: "sort_order",
                                                category: "user_action",
                                                value: option.value,
                                            });
                                            toggleIsOpen();
                                        }}
                                    >
                                        {option.label}
                                    </Button>
                                ))}
                            </div>
                        )}
                    </SearchItem>
                );
            }}
        </AssemblyProvider>
    );
};

export default SortOrder;
