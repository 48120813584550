import React from "react";
import { useSelector } from "react-redux";
import { Alert } from "reactstrap";
import MegaAssociates from "../../Search/MegaAssociates";
import { useNacLocation } from "../../../hooks/useNacLocation";

const MegaAssociatesFilter = () => {
    const { state: { nicheItemId } = {} } = useNacLocation();
    const nicheItems = useSelector((state) => state.pages.nicheItems);
    const nicheItem = nicheItems?.[nicheItemId];
    const { megaAssociates } = nicheItem || {};

    if (!megaAssociates) return null;
    return (
        <Alert color="info">
            <MegaAssociates
                data-test="niche-item-mega-associates"
                className="mb-1 ms-2"
            />
        </Alert>
    );
};

export default MegaAssociatesFilter;
