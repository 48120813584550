export const usePageAction = () => {
    const execute = (name = "", attributes = {}) => {
        if ((name, window && window?.newrelic)) {
            newrelic.addPageAction(name, attributes);
        }
    };

    return {
        execute,
    };
};
