import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import {
    getBaseUrl,
    fetchOverlord,
    buildQueryParams,
} from "../support/helpers";

export const userSearch = async (
    userId,
    options,
    searchId,
    sessionId,
    rawQuery
) => {
    const idPathPart = searchId ? `/${searchId}` : "";
    const query = buildQueryParams(rawQuery);
    const response = await fetchOverlord(
        `${getBaseUrl()}/users/${userId}/usersearch${idPathPart}${query}`,
        {
            ...options,
            headers: {
                "Content-Type": "application/json",
                sessionId,
                ...options?.headers,
            },
        }
    );
    return response;
};

export const useUserSearch = (initialResults = null) => {
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(initialResults);

    const execute = async (
        userId,
        options = {},
        searchId = undefined,
        query = {}
    ) => {
        try {
            setIsLoading(true);
            const results = await userSearch(
                userId,
                options,
                searchId,
                sessionID,
                query
            );
            if (error) setError(null);
            setData(results);
            setIsLoading(false);
            return results;
        } catch (e) {
            setError(e);
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [sessionID]), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};
