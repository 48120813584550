import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import {
    getBaseUrl,
    fetchOverlord,
    buildQueryParams,
} from "../support/helpers";

export const getNiche = async (
    sessionId,
    stateId,
    nicheItemId,
    searchValues = {},
    offmarket = undefined,
    lhr_id
) => {
    const query = buildQueryParams({ ...searchValues, lhr_id });
    const isOffMarket = offmarket ? "/offmarket" : "";
    const response = await fetchOverlord(
        `${getBaseUrl()}/states/${stateId}/niches/${nicheItemId}${isOffMarket}${query}`,
        {
            headers: {
                "Content-Type": "application/json",
                sessionId,
            },
        }
    );
    return response;
};

export const useGetNiche = (initialResults = null) => {
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(initialResults);

    const execute = async (
        stateId,
        nicheItemId,
        searchValues,
        offmarket = "",
        agentId
    ) => {
        try {
            setIsLoading(true);
            const results = await getNiche(
                sessionID,
                stateId,
                nicheItemId,
                searchValues,
                offmarket,
                agentId
            );
            setData(results);
            if (error) {
                setError(null); // resetting error
            }
            setIsLoading(false);
            return results;
        } catch (e) {
            setError(e);
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [error, sessionID]), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};
