import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { setActiveMarker } from "../../../reducers/mapReducer";
import { useQueryParams } from "use-query-params";
import { locationQueryModel } from "../../Search/SearchProvider";

const StateRegions = ({ regionsList }) => {
    const dispatch = useDispatch();
    const [locationQuery, setLocationQuery] =
        useQueryParams(locationQueryModel);
    const hasStateRegions = regionsList && regionsList.length > 0;
    const { isMobile } = useSelector((state) => state.root);

    if (!hasStateRegions) return null;

    return (
        <div
            style={isMobile ? { columnCount: 1 } : { columnCount: 2 }}
            data-test="regions-list"
        >
            {regionsList.map((region, i) => {
                if (!region?.valueLink) return null;
                const parts = region.valueLink.split("/");
                parts.shift();
                return (
                    <Button
                        key={`state-region-${i}`}
                        color="link"
                        className={`text-start border-0 m-0 p-0 w-100 text-truncate `}
                        onClick={() => {
                            setLocationQuery({
                                search: parts[1],
                                zip: parts[1],
                                range: parts[2],
                                region: region.value,
                                mapView: undefined,
                            });
                        }}
                        onMouseEnter={() =>
                            dispatch(setActiveMarker(region?.more?.id))
                        }
                        onMouseLeave={() => dispatch(setActiveMarker())}
                        data-test={`state-region`}
                    >
                        {region.value}
                    </Button>
                );
            })}
        </div>
    );
};

export default StateRegions;
