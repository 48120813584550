import React from "react";
import { AccordionItem, AccordionBody } from "reactstrap";
import PagnatedListing from "../../../../PagnatedListing";
import { useSearchData } from "../../../../../hooks/useSearchData";
import Loading from "../../../../Loading";
import ListingsTitle from "./ListingsTitle";
import StateAccordionHeader from "../StateAccordionHeader";

function AccordionListings({ id, stateId, isOpen }) {
    const { resultsData } = useSearchData();

    return (
        <AccordionItem>
            <StateAccordionHeader targetId={id} isOpen={isOpen}>
                <ListingsTitle />
            </StateAccordionHeader>
            <AccordionBody accordionId={id}>
                {!resultsData ? (
                    <Loading loadingLabel="" />
                ) : (
                    <PagnatedListing
                        title=""
                        initialFilters={{ state_code: stateId }}
                        listingItemClasses="col-12 col-sm-6"
                        appendPageNumberToUrl={true}
                    />
                )}
            </AccordionBody>
        </AccordionItem>
    );
}

export default AccordionListings;
