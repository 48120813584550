import React, { useEffect } from "react";
import { Tooltip } from "reactstrap";
import useToggle from "../../../hooks/useToggle";
import { toLowerAndReplace, getElementHeight } from "../../../support/helpers";
import DropdownSearchItem from "./DropdownSearchItem";
import DrawerSearchItem from "./DrawerSearchItem";

const SearchItem = ({
    className,
    style,
    menuStyle,
    isMobile,
    label,
    children,
    disabled,
    displayIn = "dropdown",
    displayRight = false,
    reset = undefined,
    showMenuFooter = true,
    toggleClasses,
    disableTooltip = false,
    useTooltiip = true,
    shouldLockFilters = false,
}) => {
    const [isOpen, toggleIsOpen] = useToggle(false);
    const [tooltipOpen, toggleTooltip] = useToggle(false);
    const toggleStyle = `d-flex w-100 align-items-center btn btn-primary inverse ${
        disabled ? "disabled" : ""
    }`;
    menuStyle = menuStyle + ` ${isMobile ? "border-0 w-100" : "row p-0"}`;
    const tooltipId = `searchitem${label
        .replace(/[^a-zA-Z]+/gi, "")
        .toLowerCase()}`; // replaces all special charactes and spaces
    let filtersHeight = "auto";

    const timedToggle = () => {
        if (disableTooltip) {
            toggleTooltip(true);
            setTimeout(() => toggleTooltip(false), 2500);
        }
    };

    useEffect(() => {
        if (shouldLockFilters && isMobile && isOpen) {
            document.body.classList.add("overflow-hidden");
        } else if (shouldLockFilters && isMobile && isOpen) {
            document.body.classList.remove("overflow-hidden");
        }
        return () => document.body.classList.remove("overflow-hidden");
    }, [isOpen]);

    if (
        shouldLockFilters &&
        isMobile &&
        typeof window !== "undefined" &&
        window !== null
    ) {
        const height = getElementHeight(["search-bar", "header-container"]);
        filtersHeight = `calc(100vh - ${height}px)`;
    }

    return (
        <div
            className={`${className || ""}`}
            style={style}
            data-test={`search-item-${toLowerAndReplace(label)}`}
            id={tooltipId}
        >
            {displayIn === "nowrapper" && (
                <div className="bg-white border rounded align-items-center px-3 py-2">
                    {children(isOpen, toggleIsOpen)}
                </div>
            )}
            {displayIn === "dropdown" && (
                <DropdownSearchItem
                    isMobile={isMobile}
                    isOpen={isOpen}
                    toggleIsOpen={toggleIsOpen}
                    disabled={disabled}
                    toggleStyle={toggleStyle}
                    toggleClasses={toggleClasses}
                    menuStyle={menuStyle}
                    filtersHeight={filtersHeight}
                    displayRight={displayRight}
                    shouldLockFilters={shouldLockFilters}
                    reset={reset}
                    label={label}
                    children={children}
                    showMenuFooter={showMenuFooter}
                />
            )}
            {displayIn === "drawer" && (
                <DrawerSearchItem
                    children={children}
                    reset={reset}
                    label={label}
                    disabled={disabled}
                />
            )}
            {useTooltiip &&
                typeof document !== "undefined" &&
                document !== null &&
                document.getElementById(tooltipId) && (
                    <Tooltip
                        placement="top"
                        target={tooltipId}
                        trigger="click"
                        isOpen={tooltipOpen}
                        toggle={timedToggle}
                    >
                        Please enter city or zip.
                    </Tooltip>
                )}
        </div>
    );
};

export default SearchItem;
