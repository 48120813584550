import React from "react";
import styled from "styled-components";
import { diToObject } from "../../../../support/helpers";
import { isStringOrNumber } from "../../../../support/helpers";
import PageTitle from "../../PageTitle/PageTitle";
import Divider from "../../../Divider";
import { FaCalculator } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNacLocation } from "../../../../hooks/useNacLocation";

const Header = ({ className, address, highlights, bullets }) => {
    const { line1, city, state, zip } = address;
    const { price, category } = diToObject(highlights);
    const pContent =
        (isStringOrNumber(city) ? `${city}, ` : "") +
        (isStringOrNumber(state) ? `${state} ` : "") +
        isStringOrNumber(zip);
    const { state: { listingId } = {} } = useNacLocation();
    const listingHighlights = useSelector(
        (state) => state.pages?.listings[listingId]?.highlights
    );
    const isOffMarket =
        listingHighlights?.find((h) => h.id === "status").value ===
        "Off Market";

    return (
        <div className={className} data-test="listing-header">
            <HeaderStyles className="d-flex flex-column-reverse flex-md-row">
                <PageTitle
                    title={line1 && line1.toLowerCase()}
                    titleClass={`flex-grow-1 mb-0 mt-1`}
                    subtitle={pContent}
                />
                <div className="d-flex align-items-center flex-wrap d-md-block ms-md-auto text-md-end">
                    {price?.value ? (
                        <div className="d-flex align-items-center justify-content-end">
                            <h3 className="fs-2 mb-0">{price.value}</h3>
                            {!isOffMarket && (
                                <FaCalculator
                                    onClick={() => {
                                        document
                                            .getElementById("financial-info")
                                            .scrollIntoView({
                                                behavior: "smooth",
                                            });
                                    }}
                                    style={{
                                        cursor: "pointer",
                                        color: "var(--bs-primary)",
                                    }}
                                    className="ms-2"
                                />
                            )}
                        </div>
                    ) : (
                        <div>Price disclosed to qualified buyer</div>
                    )}
                    <div className="ms-auto small bullets">
                        {bullets?.bedBath && (
                            <Divider>
                                <div
                                    className="d-inline"
                                    dangerouslySetInnerHTML={{
                                        __html: bullets.bedBath,
                                    }}
                                />
                            </Divider>
                        )}
                        {bullets?.sqft && <Divider>{bullets.sqft}</Divider>}
                        {bullets?.acreage && (
                            <Divider>{bullets.acreage}</Divider>
                        )}
                    </div>
                    <div className="d-none d-md-block small">
                        {category?.value && <div>{category.value}</div>}
                    </div>
                </div>
            </HeaderStyles>
        </div>
    );
};
const HeaderStyles = styled.div`
    @media (max-width: 768px) {
        h1 {
            font-size: calc(1.3rem + 0.6vw);
        }
        .bullets {
            span:nth-child(2):after,
            span:nth-child(3) {
                display: none;
            }
        }
    }
`;

export default Header;
