import React, { Suspense } from "react";
import Loading from "../../Loading";

export const DynamicComponent = ({ componentName, ...props }) => {
    const Component = React.lazy(() => import(`../${componentName}`));

    return (
        <Suspense fallback={<Loading />}>
            {Component ? (
                <Component {...props} />
            ) : (
                <div>Component not found</div>
            )}
        </Suspense>
    );
};
