import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setMarkers, setGisDefault } from "../../../reducers/mapReducer";
import { useDevelopment } from "../../../hooks/useDevelopment";
import bulkPageReducer from "../../../reducers/bulkPageReducer";
import { getPageType } from "../../../support/helpers";
import { setIsFetchingPage, setPageError } from "../../../reducers/rootReducer";
import { setPageHeaderInfo } from "../../../reducers/pagesReducer";
import { useSearchData } from "../../../hooks/useSearchData";
import { useQueryParam, StringParam } from "use-query-params";
import { useNacLocation } from "../../../hooks/useNacLocation";

/**
 * A Development is just a subset of a Niche. The Development component loads its data and then
 * uses the Niche container passing the isDevelopment prop.
 */
const DevelopmentProvider = ({ children }) => {
    const dispatch = useDispatch();
    const [mapView] = useQueryParam("mapView", StringParam);
    const developments = useSelector((state) => state.pages.developments);
    const { gisData: gis } = useSearchData();
    const isListingsView = mapView === "listings";
    const routeParams = useParams();
    const isDevelopmentPage = getPageType(routeParams, "development");
    const { state: { stateId, nicheItemId, developmentId, agentId } = {} } =
        useNacLocation();
    const { execute: getDevelopment, error } = useDevelopment();
    const development = developments?.[developmentId];
    const { gISDefault, childNicheItems } = development || {};
    useEffect(() => {
        if (
            development?.pageHeaderInfo &&
            getPageType(routeParams, "development")
        ) {
            dispatch(setPageHeaderInfo(development?.pageHeaderInfo));
        }
    }, [development, dispatch, routeParams]);

    useEffect(() => {
        if (developmentId && !development) {
            const fetchData = async () => {
                dispatch(setIsFetchingPage(true));
                const pageData = {
                    pageType: "nicheItem_development",
                    ...(await getDevelopment(
                        stateId,
                        nicheItemId,
                        developmentId,
                        { gispreview: true }
                    )),
                };
                bulkPageReducer(dispatch, pageData);
                dispatch(setIsFetchingPage(false));
            };
            if (fetchData) fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [developmentId, isDevelopmentPage]);

    useEffect(() => {
        if (isDevelopmentPage && developmentId) {
            if (gISDefault) dispatch(setGisDefault(gISDefault));
            if (
                childNicheItems &&
                !isListingsView &&
                childNicheItems?.dILinks.length > 0
            ) {
                dispatch(
                    setMarkers(
                        childNicheItems.dILinks.map((item) => ({
                            ...item,
                            ...item.more,
                            type: "region",
                            click: {
                                path: item.valueLink,
                                state: { nicheItemId: item.more.nicheItemID },
                            },
                        }))
                    )
                );
            } else if (gis || development) {
                const nichListings = gis
                    ? gis?.results || []
                    : development?.gis?.results || [];
                if (Array.isArray(nichListings) && nichListings.length > 0) {
                    dispatch(
                        setMarkers(
                            nichListings.map((listing) => ({
                                ...listing,
                                type: "listing",
                                click: {
                                    path: listing.urlPath,
                                    state: { listingId: listing.listingId },
                                },
                            }))
                        )
                    );
                }
            } else {
                dispatch(setMarkers([]));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [developmentId, gis, isListingsView, development]);

    useEffect(() => {
        if (error) {
            dispatch(
                setPageError({
                    page: "Development",
                    error: "Error loading development page.",
                })
            );
        }
    }, [dispatch, error]);

    return children;
};

export default DevelopmentProvider;
