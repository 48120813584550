import React from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import Fetching from "../../Fetching";
import ListedBy from "./ListedBy";

import ErrorBoundary from "../../ErrorBoundary";
import { useNacLocation } from "../../../hooks/useNacLocation";

function Main() {
    const listings = useSelector((state) => state.pages.listings);
    const { state: { listingId = undefined } = {} } = useNacLocation();
    const listing = listings?.[listingId];
    const { description, virtualTours, courtesyOfProminent } = listing || {};

    return (
        <Fetching waitUntil={listing}>
            <div data-testid="listing-main">
                <div className="p-3 ps-xxl-0">
                    <div className="d-md-flex w-100 justify-content-between mb-md-2">
                        {courtesyOfProminent && (
                            <ListedBy
                                courtesyOfProminent={courtesyOfProminent}
                            />
                        )}
                        <div data-test="virtual-tours">
                            {virtualTours &&
                                virtualTours.map((tour, i) => (
                                    <Button
                                        key={`virtual-tour-${i}`}
                                        color="primary"
                                        className="mb-2 w-100"
                                        href={tour.valueLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {virtualTours.length > 1
                                            ? `Virtual Tour ${i + 1}`
                                            : "Take a Virtual Tour"}
                                    </Button>
                                ))}
                        </div>
                    </div>
                    <ErrorBoundary>
                        <div
                            style={{ whiteSpace: "pre-line" }}
                            data-test="listing-description"
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </ErrorBoundary>
                </div>
            </div>
        </Fetching>
    );
}

export default Main;
