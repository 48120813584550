import React from "react";
import { useSelector } from "react-redux";
import { LuWaves } from "react-icons/lu";

function RegionTitle() {
    const { nichetypeSingular } = useSelector((state) => state.root.styling);

    return (
        <div className="d-flex align-items-center lh-1">
            <div className="regions border border-primary rounded-1">
                <LuWaves className="m-1" />
            </div>
            <span className="ms-2">{nichetypeSingular} Regions</span>
        </div>
    );
}

export default RegionTitle;
