import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { getBaseUrl, fetchOverlord } from "../support/helpers";

export const queryMicrocontent = async (
    category,
    item,
    microcontentId,
    action,
    options,
    sessionId
) => {
    let endOfUrl = `${category}/${item}/${microcontentId}`;
    if (action) endOfUrl += `/${action}`;
    const response = await fetchOverlord(
        `${getBaseUrl()}/microcontent/${endOfUrl}`,
        {
            ...options,
            headers: {
                "Content-Type": "application/json",
                sessionId,
                ...options?.headers,
            },
        }
    );
    return response;
};

export const useMicrocontent = (initialResults = null) => {
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(initialResults);

    const execute = async (
        category,
        item,
        microcontentId,
        options = {},
        action
    ) => {
        try {
            setIsLoading(true);
            const results = await queryMicrocontent(
                category,
                item,
                microcontentId,
                action,
                options,
                sessionID
            );
            if (error) setError(null);
            setData(results);
            setIsLoading(false);
            return results;
        } catch (e) {
            setError(e);
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [sessionID]), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};

export default useMicrocontent;
