import React from "react";
import { useDispatch } from "react-redux";
import Icon from "../Icon";
import DiItem from "./DiItem";
import { setActiveMarker } from "../../reducers/mapReducer";

const DiItems = ({ items, ...props }) => {
    const {
        Wrapper = "div",
        wrapperClsses = "", // TODO: change this to className
        wrapperStyle = {}, // TODO: change this to style
        labelClasses = "",
        LabelEl = "span",
        listingsClasses = "",
        verticalLabelClasses = "",
        valueClasses = "",
        noLabelValueClasses = "",
        valueLinkClasses = "",
        verticalValueClasses = "",
        valueIsHtml = false,
        labelSuffixClasses = "",
        suffixIsHtml = false,
        iconClasses = "",
        noValueDisplayWrapper = false,
        noSuffixDisplayWrapper = false,
        buttonColor = "link",
        dataTest,
        clickHandler,
        linkQueryParams = "",
        excludeIdsFromState = [],
    } = props;

    const dispatch = useDispatch();
    if (!items) return null;
    return (
        items &&
        items.map((item, i) => {
            if (!item) return null;
            const hasNoLabel = item?.label === undefined;
            const itemDisp = item?.Disp || item?.disp;
            return (
                <Wrapper
                    key={`di-${item.id}-${i}`}
                    className={`${wrapperClsses} ${item.id}`}
                    style={wrapperStyle}
                    data-test={dataTest}
                    onMouseEnter={() => {
                        if (item?.more?.id)
                            dispatch(setActiveMarker(item?.more?.id));
                    }}
                    onMouseLeave={() => {
                        if (item?.more?.id) dispatch(setActiveMarker());
                    }}
                >
                    {item.imageUrl && (
                        <img
                            src={item.imageUrl}
                            alt={item.value} // TODO: this could possibly be replaced with something more useful
                            height={item.imageHeight}
                            width={item.imageWidth}
                        />
                    )}
                    {item.iconName && (
                        <Icon
                            className={iconClasses}
                            icon={item.iconName}
                            size={32}
                        />
                    )}
                    {item.label && (
                        <LabelEl
                            className={`label ${labelClasses} ${
                                itemDisp === "V" && verticalLabelClasses
                            }`}
                        >
                            {item.label}{" "}
                        </LabelEl>
                    )}
                    <DiItem
                        {...item}
                        type="value"
                        linkClasses={valueLinkClasses}
                        classes={`value ${
                            hasNoLabel ? noLabelValueClasses : valueClasses
                        } ${itemDisp === "V" && verticalValueClasses}`}
                        noDisplayWrapper={noValueDisplayWrapper}
                        Disp={itemDisp}
                        isHtml={valueIsHtml}
                        buttonColor={buttonColor}
                        listingsClasses={listingsClasses}
                        clickHandler={clickHandler}
                        linkQueryParams={linkQueryParams}
                        excludeIdsFromState={excludeIdsFromState}
                    />
                    <DiItem
                        {...item}
                        type="suffix"
                        classes={`suffix ${labelSuffixClasses}`}
                        noDisplayWrapper={noSuffixDisplayWrapper}
                        isHtml={suffixIsHtml}
                        buttonColor={buttonColor}
                        linkQueryParams={linkQueryParams}
                        excludeIdsFromState={excludeIdsFromState}
                    />
                    {item.seeLink && (
                        <span className="seeLink">
                            &nbsp;(See{" "}
                            <a
                                href={item.seeLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {item.seeLink}
                            </a>
                            )
                        </span>
                    )}
                    {item?.children && (
                        <DiItems items={item.children} {...props} />
                    )}
                </Wrapper>
            );
        })
    );
};

export default DiItems;
