import React, { useEffect, useState } from "react";
import { Alert, Collapse } from "reactstrap";

const DisappearingAlert = ({ time, text, color, setError, shouldShow }) => {
    useEffect(() => {
        if (shouldShow) setTimeout(() => setError(), time);
    }, [shouldShow]);

    return (
        <>
            <Collapse isOpen={shouldShow} className="pb-1">
                <Alert color={color}>{text}</Alert>
            </Collapse>
        </>
    );
};

export default DisappearingAlert;
