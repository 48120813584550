import React from "react";
import { Badge } from "reactstrap";

const ContentStatusBadge = ({ status, key, ...props }) => {
    if (status !== "DRAFT" && status !== "2REVIEW") return null;
    return (
        <Badge
            key={key}
            {...props}
            color={status === "DRAFT" ? "primary" : "warning"}
        >
            {status === "DRAFT" ? "Draft" : "Marked for review"}
        </Badge>
    );
};

export default ContentStatusBadge;
