import React from "react";
import { useSelector } from "react-redux";
import { Accordion } from "reactstrap";
import { useQueryParam, StringParam } from "use-query-params";
import { useSearchData } from "../../../../hooks/useSearchData";
import styled from "styled-components";
import AccordionNicheItems from "./NicheItems";
import AccordionListings from "./Listings";
import AccordionRegions from "./Regions";
import { useNacLocation } from "../../../../hooks/useNacLocation";

function StateAccordion() {
    const { state: { stateId } = {} } = useNacLocation();
    const stateRegions =
        useSelector((state) => state.pages.states?.[stateId]?.stateRegions) ||
        [];
    const nichetypePlural =
        useSelector(
            (state) => state.root.styling.nichetypePlural
        ).toLowerCase() || "niches";
    const { hasSearched } = useSearchData();
    const hasRegions = stateRegions.length > 0;
    const [mapView, setMapView] = useQueryParam("mapView", StringParam);
    const defaultView = hasSearched
        ? "listings"
        : hasRegions
        ? "regions"
        : nichetypePlural;
    const open = mapView || defaultView;
    const toggle = (id) => {
        if (id !== open) {
            setMapView(id);
        } else if (hasSearched) {
            setMapView(id === "listings" ? nichetypePlural : "listings");
        } else if (hasRegions) {
            setMapView(id === "regions" ? nichetypePlural : "regions");
        } else {
            setMapView();
        }
    };

    return (
        <StateAccordionWrapper open={open} toggle={toggle}>
            <AccordionNicheItems
                id={nichetypePlural}
                stateId={stateId}
                isOpen={open === nichetypePlural}
            />
            {hasRegions && !hasSearched && (
                <AccordionRegions
                    id="regions"
                    stateId={stateId}
                    isOpen={open === "regions"}
                />
            )}
            {hasSearched && (
                <AccordionListings
                    id="listings"
                    stateId={stateId}
                    isOpen={open === "listings"}
                />
            )}
        </StateAccordionWrapper>
    );
}

const StateAccordionWrapper = styled(Accordion)`
    .accordion-header {
        @media (min-width: 992px) {
            --bs-accordion-btn-icon-transform: rotate(-90deg);
        }
        --bs-accordion-btn-icon-color: var(--bs-primary);
        .accordion-button {
            line-height: 0.5rem;
        }
    }
    .accordion-body {
        --bs-accordion-body-padding-y: 0 1rem;
    }
    .accordion-item {
        border-radius: var(--bs-accordion-border-radius);
        border-color: var(--bs-primary);
        border-top: 1px solid var(--bs-primary) !important;
        margin-bottom: 1rem !important;
    }
    .accordion-button {
        --bs-accordion-active-bg: transparent;
        --bs-accordion-btn-bg: transparent;
        --bs-accordion-btn-color: var(--bs-primary);
        --bs-accordion-btn-focus-box-shadow: none;
        border-radius: var(--bs-accordion-border-radius);
        &:not(.collapsed) {
            box-shadow: none;
        }
        &.collapsed:hover {
            background-color: var(--bs-primary);
            color: var(--bs-white);
            .regions {
                border-color: var(--bs-white) !important;
            }
            &:after {
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
            }
        }
        &:after {
            background-image: var(--bs-accordion-btn-active-icon);
        }
    }
`;

export default StateAccordion;
