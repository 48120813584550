import React from "react";
import { Button } from "reactstrap";
import useTrackEvent from "../../hooks/useTrackEvent";

/**
 * Renders the agent information including agent name, image, contact details, and brokerage information.
 *
 * @param {Object} agent - The agent object containing agentName, agentPic, agentWebsitePhone, agentMobilePhone, agentOfficePhone, brokerageName, and title.
 * @return {JSX.Element} The rendered agent information component.
 */
const AgentInfo = ({
    agent: {
        agentName,
        agentPic: { imageUrl },
        agentWebsitePhone,
        agentMobilePhone,
        agentOfficePhone,
        brokerageName,
        title,
    },
}) => {
    const { trackEvent, trackGTM } = useTrackEvent();

    return (
        <>
            <div className="d-flex pb-2 border-bottom mb-2">
                <div className="me-3">
                    {imageUrl && (
                        <img
                            src={
                                imageUrl || "/assets/no-image-found-360x260.png"
                            }
                            onError={(e) =>
                                (e.target.src = "/no-image-found-360x260.png")
                            }
                            alt={agentName.value}
                            height="125px"
                        />
                    )}
                </div>
                <div className="flex-grow-1">
                    {agentName && <span className="h4">{agentName.value}</span>}
                    {title && <div className="text-muted">{title}</div>}
                    {brokerageName?.value && (
                        <div className="text-muted text-nowrap">
                            {brokerageName.value}
                        </div>
                    )}
                    <div>
                        {(agentWebsitePhone ||
                            agentMobilePhone ||
                            agentOfficePhone) && (
                            <Button
                                className="text-start p-0"
                                color="link"
                                href={`tel:${
                                    agentWebsitePhone?.value ||
                                    agentMobilePhone?.value ||
                                    agentOfficePhone?.value
                                }`}
                                onClick={() => {
                                    trackGTM({
                                        event: `phoneClick`,
                                        action: "click",
                                        type: "Agent",
                                        category: "contact",
                                        button_label: agentMobilePhone?.value,
                                        value: agentMobilePhone?.value,
                                        ...agentName,
                                    });
                                    trackEvent("ACTIONS", {
                                        CATEGORY: "phone",
                                        ASSOCIATE: "Agent",
                                        ACTION: "click",
                                        SOURCE_INFO: {
                                            eventValue: agentName?.more?.id,
                                        },
                                    });
                                }}
                            >
                                {agentWebsitePhone
                                    ? agentWebsitePhone.value
                                    : agentMobilePhone
                                    ? agentMobilePhone?.value
                                    : agentOfficePhone?.value}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AgentInfo;
