/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *      Button that opens a 'contact agent' modal
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-11   BL          22315      1            Re-structured files and renamed to index.js
 *
 */

import React from "react";
import { Button, ModalBody } from "reactstrap";
import Drawer from "../../../Drawer";
import AgentInfo from "../../../AgentInfo";
import ContactForm from "../../../Contact";
import useToggle from "../../../../hooks/useToggle";
import useTrackEvent from "../../../../hooks/useTrackEvent";
import { omit } from "../../../../support/helpers";
import { useNacLocation } from "../../../../hooks/useNacLocation";

const omitKyes = ["children", "contactType", "featuredAgent", "header"];

const Contact = (props) => {
    const {
        children,
        contactType = "MoreInfo",
        header = "Request More Info",
        featuredAgent,
    } = props;
    const [modalIsOpen, toggleModal] = useToggle(props?.modalIsOpen || false);
    const { trackEvent, trackGTM } = useTrackEvent();
    const {
        state: { nicheItemId, listingId },
    } = useNacLocation();

    return (
        <>
            <Button
                {...omit(props, omitKyes)}
                onClick={() => {
                    if (props.onClick) props.onClick();
                    toggleModal();
                    trackGTM({
                        event: `formContact`,
                        action: "click",
                        type: contactType,
                        category: "contact",
                        button_label: children[1] || undefined,
                        featuredAgent: featuredAgent?.agentName,
                        listingId,
                        nicheItemId,
                    });
                    trackEvent("ACTIONS", {
                        CATEGORY: "event",
                        ASSOCIATE: "Contact Start",
                        ACTION: "click",
                        SOURCE_INFO: {
                            eventLabel: contactType,
                            eventValue:
                                featuredAgent?.agentName?.more?.id ||
                                listingId ||
                                nicheItemId,
                        },
                    });
                }}
            >
                {children}
            </Button>
            <Drawer
                isOpen={modalIsOpen}
                toggle={() => toggleModal()}
                headerText={header}
            >
                <ModalBody>
                    {featuredAgent && <AgentInfo agent={featuredAgent} />}
                    <ContactForm
                        contactType={contactType}
                        agentId={featuredAgent?.agentName?.more?.id}
                        closeModal={() => toggleModal(false)}
                    />
                </ModalBody>
            </Drawer>
        </>
    );
};

export default Contact;
