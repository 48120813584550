import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import {
    getAssembliesByGroup,
    getAssemblyItem,
    toLowerAndReplace,
    getPageType,
} from "../../support/helpers";

const assemblyName = "USER_SEARCH";
const assemblyGroup = "USER_SEARCH";

const ListButtons = ({
    className,
    itemName,
    currentValue,
    setValue,
    callback,
}) => {
    const routeParams = useParams();
    const isState = getPageType(routeParams, "state");
    const assemblies = useSelector((state) => state.root.assemblies);
    const assembly = getAssembliesByGroup(
        assemblies?.[assemblyName],
        assemblyGroup
    );
    if (!assembly) return null;
    const assemblyItem = getAssemblyItem(assembly, itemName);
    const options = [...assemblyItem?.listInfo?.list];

    // We have to do this little hack-a-roni because our USER_SEARCH assembly contains the order for all pages and
    // the state sort uses distance but not Featured while all others use the inverse.
    if (itemName === "sort_order") {
        if (isState) {
            options.splice(1, 1);
        } else {
            options.splice(0, 1);
        }
    }

    const selectedOption = currentValue
        ? options.find((item) => item.value === currentValue.toString())
        : options[0];

    const handleClick = (value) => {
        setValue(value, options);
        if (callback) callback();
    };

    return (
        <div className={className}>
            {options.map((option) => (
                <Button
                    key={`option-${toLowerAndReplace(option.label)}`}
                    className="d-block w-100 my-1"
                    color={
                        selectedOption.label === option.label
                            ? "primary"
                            : "light"
                    }
                    onClick={() => handleClick(option.value)}
                    data-test={`list-button-${toLowerAndReplace(
                        option.label.replace("/", "-")
                    )}`}
                >
                    {option.label}
                </Button>
            ))}
        </div>
    );
};

export default ListButtons;
