import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import Search from "../../Search/Search";
import { NicheItems } from "../Niche";
import DebouncedInput from "../../DebouncedInput";

/**
 * Remove unnecessary items from string to compare
 * @param {*} value string to have parts replaced
 * @returns string with parts replaced
 */
export const theReplacements = (value) =>
    value.toLowerCase().replaceAll(" ", "").replaceAll("-", "");

/**
 * List of niche items including filter search and MLS search.
 */
const StateNicheList = ({ nicheList, stateId, stateData }) => {
    const [filterValue, setFilterValue] = useState("");
    const stateName = stateData?.name;
    const nichetypeSingular = useSelector(
        (state) => state.root.styling.nichetypeSingular
    );

    const filterNicheItems = useCallback(
        (value) => {
            return value
                ? nicheList.filter((item) => {
                      const lowerCaseValue = theReplacements(value);
                      if (
                          theReplacements(item.value).includes(lowerCaseValue)
                      ) {
                          return true;
                      } else if (item?.more?.children) {
                          return (item?.more?.children || []).find(
                              (childItem) => {
                                  return theReplacements(
                                      childItem.name
                                  ).includes(lowerCaseValue);
                              }
                          );
                      }
                  })
                : nicheList;
        },
        [nicheList]
    );

    return (
        <div data-test="state-niche-list">
            <div className="row g-0 mb-3">
                <div className="col-6">
                    <DebouncedInput
                        className="ps-2 me-1"
                        placeholder={`Enter ${nichetypeSingular} Name`}
                        data-test="niche-filter-input"
                        onChange={(value) => setFilterValue(value)}
                    />
                </div>
                <div className="col-6">
                    <Search
                        className="h-100 w-100 pl-3 ms-1"
                        isMlsSearch
                        state_code={stateId}
                        includes="mls"
                        placeholder="Enter MLS#"
                        locationOnPage={stateName}
                    />
                </div>
            </div>
            <NicheItems
                items={filterNicheItems(filterValue)}
                filterValue={filterValue}
                noValueDisplayWrapper={true}
            />
        </div>
    );
};

export default StateNicheList;
