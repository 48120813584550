import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import styled from "styled-components";

const CustomArrow = ({ onClick, arrowDirection, isDisabled }) => {
    const style =
        arrowDirection === "left" ? { left: "10px" } : { right: "10px" };
    return (
        <Arrow
            className="position-fixed"
            disabled={isDisabled}
            onClick={onClick}
            style={style}
        >
            {arrowDirection === "left" ? <FaChevronLeft /> : <FaChevronRight />}
        </Arrow>
    );
};

const Arrow = styled.div`
    font-size: 1.5em;
    color: ${(props) => (props.disabled ? "var(--bs-light-gray)" : "inherit")};
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};

    &:hover {
        color: ${(props) =>
            props.disabled ? "var(--bs-light-gray)" : "var(--bs-primary)"};
    }
`;

export default CustomArrow;
