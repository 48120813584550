import React from "react";

const ListedBy = ({ courtesyOfProminent }) => {
    const { dIListingDetails } = courtesyOfProminent || {};
    const office = dIListingDetails.find(
        (item) => item.id === "listing_office"
    );
    const agent = dIListingDetails.find((item) => item.id === "listing_agent");
    if (!agent) return null;
    return (
        <div>
            {agent?.value && <div>Listed By: {agent.value}</div>}
            {office?.value && <div>{office.value}</div>}
        </div>
    );
};

export default ListedBy;
